<template>
  <request-options :infoImg="infoImg" :dialogId="dialogId"></request-options>
</template>
<script>
import RequestOptions from '../../../components/dialog/RequestOptions.vue'
export default {
  components: {
    RequestOptions
  },
  props: {
    infoImg: {
      type: String,
      required: true
    },
    dialogId: {
      type: String,
      required: true
    }
  }
}
</script>
