var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("session-end-screen", {
    attrs: { infoImg: _vm.infoImg },
    on: {
      "dialog-updated": function (updatedDialog) {
        return _vm.$emit("dialog-updated", updatedDialog)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }