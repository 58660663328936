var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "testing-banner" }, [
    _c("div", { staticClass: "testing-banner__info" }, [
      _c(
        "svg",
        {
          staticClass: "testing-banner__info-icon",
          attrs: {
            width: "22",
            height: "22",
            viewBox: "0 0 22 22",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z",
              fill: "white",
              stroke: "#3B86F7",
              "stroke-width": "1.5",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          }),
          _c("path", {
            attrs: {
              d: "M11 7V11",
              stroke: "#3B86F7",
              "stroke-width": "1.5",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          }),
          _c("path", {
            attrs: {
              d: "M11 15H11.01",
              stroke: "#3B86F7",
              "stroke-width": "1.5",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          }),
        ]
      ),
      _c(
        "div",
        { staticClass: "testing-banner__text" },
        [_vm._t("default")],
        2
      ),
    ]),
    _c(
      "a",
      {
        staticClass: "testing-banner__action",
        attrs: { href: "#" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.$emit("on-action-click")
          },
        },
      },
      [
        _c("span", [_vm._t("link")], 2),
        _c(
          "svg",
          {
            staticClass: "testing-banner__action-icon",
            attrs: {
              width: "22",
              height: "12",
              viewBox: "0 0 22 12",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M1 6.10547L20.4319 6.10547",
                stroke: "#fff",
                "stroke-width": "1.5",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
              },
            }),
            _c("path", {
              attrs: {
                d: "M15 1.00085L21 6.10394L15 11.207",
                stroke: "#fff",
                "stroke-width": "1.5",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
              },
            }),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }