var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "vx-row w-full mt-5" }, [
        _c(
          "div",
          { staticClass: "vx-row w-full flex-box-column-start ml-5" },
          [
            _c("vs-col", { attrs: { "vs-type": "flex", "vs-w": "12" } }),
            _c("vs-col", { attrs: { "vs-type": "flex", "vs-w": "12" } }, [
              _c("label", [
                _vm._v(_vm._s(_vm.$t("inputs.giveYourdialogName"))),
              ]),
            ]),
            _c("vs-col", { attrs: { "vs-w": "12" } }, [
              _c(
                "div",
                { staticClass: "flex-box-row-start" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.immediate",
                        value:
                          "required|min:3|isValidDialogName:" +
                          JSON.stringify({
                            oldVal: _vm.oldDialogName,
                            dialogs: _vm.dialogNames,
                          }),
                        expression:
                          "`required|min:3|isValidDialogName:${JSON.stringify({ oldVal: oldDialogName, dialogs: dialogNames })}`",
                        modifiers: { immediate: true },
                      },
                    ],
                    staticClass: "w-full mr-2",
                    staticStyle: {
                      display: "inline-block",
                      "max-width": "520px",
                    },
                    attrs: { name: "dialog-name" },
                    model: {
                      value: _vm.dialogName,
                      callback: function ($$v) {
                        _vm.dialogName = $$v
                      },
                      expression: "dialogName",
                    },
                  }),
                  _c(
                    "vx-tooltip",
                    {
                      attrs: {
                        text: _vm.$t("info.dialogName"),
                        position: "top",
                      },
                    },
                    [_c("img", { attrs: { src: _vm.infoImg } })]
                  ),
                ],
                1
              ),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("dialog-name"))),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row w-full mt-5" }, [
        _c(
          "div",
          { staticClass: "vx-row w-full flex-box-column-start ml-5" },
          [
            _c("vs-col", { attrs: { "vs-type": "flex", "vs-w": "12" } }, [
              _c("label", [
                _vm._v(_vm._s(_vm.$t("inputs.setDisplayCompanyName"))),
              ]),
            ]),
            _c("vs-col", { attrs: { "vs-w": "12" } }, [
              _c(
                "div",
                { staticClass: "flex-box-row-start" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.immediate",
                        value: "max:50",
                        expression: "'max:50'",
                        modifiers: { immediate: true },
                      },
                    ],
                    staticClass: "w-full mr-2",
                    staticStyle: {
                      display: "inline-block",
                      "max-width": "520px",
                    },
                    attrs: { name: "dialog-company-display-name" },
                    model: {
                      value: _vm.companyDisplayName,
                      callback: function ($$v) {
                        _vm.companyDisplayName = $$v
                      },
                      expression: "companyDisplayName",
                    },
                  }),
                  _c(
                    "vx-tooltip",
                    {
                      attrs: {
                        text: _vm.$t("info.setDisplayCompanyName"),
                        position: "top",
                      },
                    },
                    [_c("img", { attrs: { src: _vm.infoImg } })]
                  ),
                ],
                1
              ),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("dialog-company-display-name"))),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row w-full mt-5" }, [
        _c(
          "div",
          { staticClass: "vx-row w-full flex-box-column-start ml-5" },
          [
            _c(
              "vs-col",
              {
                staticClass: "language-selection-select",
                attrs: { "vs-w": "12" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("vue.selectLanguageText")) +
                    "\n      "
                ),
              ]
            ),
            _c("vs-col", { attrs: { "vs-w": "12" } }, [
              _c(
                "div",
                { staticClass: "flex-box-row-start" },
                [
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass:
                      "language-selection-select mr-2 mb-4 md:mb-0 w-full",
                    staticStyle: {
                      display: "inline-block",
                      "max-width": "520px",
                    },
                    attrs: {
                      name: "language-select",
                      options: _vm.webconnectSupportedLanguages,
                      placeholder: _vm.$t("vue.selectLanguage"),
                      label: "lnFullText",
                      clearable: false,
                      dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      "validate-on": "blur",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "option",
                        fn: function (option) {
                          return [
                            _c("div", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(option.lnFullText) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selectedLanguage,
                      callback: function ($$v) {
                        _vm.selectedLanguage = $$v
                      },
                      expression: "selectedLanguage",
                    },
                  }),
                  _c(
                    "vx-tooltip",
                    {
                      attrs: {
                        text: _vm.$t("info.languageSelection"),
                        position: "top",
                      },
                    },
                    [_c("img", { attrs: { src: _vm.infoImg } })]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row w-full mt-5" }, [
        _c(
          "div",
          { staticClass: "vx-row w-full flex-box-column-start ml-5" },
          [
            _c("vs-col", { attrs: { "vs-type": "flex", "vs-w": "12" } }, [
              _c("label", [
                _vm._v(_vm._s(_vm.$t("ratingScreen.widgetUseSelectionLabel"))),
              ]),
            ]),
            _c("vs-col", { attrs: { "vs-w": "12" } }, [
              _c(
                "div",
                { staticClass: "flex-box-row-start" },
                [
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "rating-screen-select mb-4 md:mb-0 w-full",
                    staticStyle: {
                      display: "inline-block",
                      "max-width": "520px",
                    },
                    attrs: {
                      name: "widget-use",
                      options: _vm.widgetUses,
                      reduce: function (uses) {
                        return uses.value
                      },
                      placeholder: _vm.$t("ratingScreen.placeholderWidgetUse"),
                      label: "label",
                      clearable: false,
                      dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      "validate-on": "blur",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "option",
                        fn: function (option) {
                          return [
                            _c("div", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(option.label) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.widgetUse,
                      callback: function ($$v) {
                        _vm.widgetUse = $$v
                      },
                      expression: "widgetUse",
                    },
                  }),
                ],
                1
              ),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("widget-use"))),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row w-full mt-5" }, [
        _c(
          "div",
          { staticClass: "vx-row w-full flex-box-column-start ml-5" },
          [
            _c("vs-col", { attrs: { "vs-type": "flex", "vs-w": "12" } }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("vue.tryoutWidgetText")))]),
            ]),
            _c(
              "vs-col",
              { attrs: { "vs-type": "flex", "vs-w": "12" } },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "mt-2 mr-2 mb-2",
                    attrs: { type: "border", "icon-pack": "feather" },
                    on: { click: _vm.testYourWidget },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("vue.testYourWidget")) +
                        "\n        "
                    ),
                  ]
                ),
                _c(
                  "vs-button",
                  {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: _vm.testUrl,
                        expression: "testUrl",
                        arg: "copy",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:success",
                        value: _vm.onCopy,
                        expression: "onCopy",
                        arg: "success",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:error",
                        value: _vm.onError,
                        expression: "onError",
                        arg: "error",
                      },
                    ],
                    staticClass: "mt-2 mr-2 mb-2",
                    attrs: { type: "border" },
                  },
                  [
                    _c("div", { staticClass: "flex-center" }, [
                      _c("div", {
                        staticStyle: {
                          width: "10px",
                          height: "10px",
                          background: "#3b86f7",
                          "border-radius": "10px",
                          "margin-right": "10px",
                        },
                      }),
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("vue.testWebsiteUrlCopy"))),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row w-full mt-5" }, [
        _c(
          "div",
          { staticClass: "vx-row w-full flex-box-column-start ml-5" },
          [
            _c("vs-col", { attrs: { "vs-type": "flex", "vs-w": "12" } }, [
              _c("div", { staticClass: "step-headline" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("vue.websiteIntegration")) +
                    "\n        "
                ),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row w-full" }),
      _c("website-integration", {
        attrs: { dialogId: _vm.dialogId, infoImg: _vm.infoImg },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }