<template>
  <session-end-screen :infoImg="infoImg" @dialog-updated="(updatedDialog) => $emit('dialog-updated', updatedDialog)"></session-end-screen>
</template>
<script>
import SessionEndScreen from '../../../components/dialog/SessionEndScreen.vue'
export default {
  components: {
    SessionEndScreen
  },
  props: {
    infoImg: {
      type: String,
      required: true
    }
  }
}
</script>
