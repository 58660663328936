var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", {
        domProps: { innerHTML: _vm._s(_vm.dataProtectionTextHtml) },
      }),
      _c("div", { staticClass: "dialog-consent" }, [
        _c(
          "div",
          {
            staticClass:
              "dialog-consent-content flex flex-col items-center justify-center dialog-consent-absolute",
            class: _vm.showConsentToolSelection ? "" : "left-p1000",
          },
          [
            _c("div", { staticClass: "dialog-consent-bold" }, [
              _vm._v(_vm._s(_vm.$t("vue.consentToolQuestion"))),
            ]),
            _c("div", { staticClass: "flex flex-row justify-center mt-5" }, [
              _c(
                "div",
                {
                  staticClass:
                    "dialog-consent-button flex justify-center items-center m-2",
                  on: {
                    click: function ($event) {
                      return _vm.useContentTool(true)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("vue.usingConsentTool")))]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "dialog-consent-button flex justify-center items-center m-2",
                  on: {
                    click: function ($event) {
                      return _vm.useContentTool(false)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("vue.notUsingConsentTool")))]
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "dialog-consent-content p-5 dialog-consent-absolute",
            class:
              _vm.showConsentToolSelection || !_vm.usingConsentTool
                ? "left-500"
                : "",
          },
          [
            _c(
              "div",
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-row items-center mb-2 cursor-pointer",
                    on: {
                      click: function ($event) {
                        return _vm.onBackToSelection()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex items-center justify-center" },
                      [
                        _c(
                          "div",
                          { staticClass: "mr-2" },
                          [_c("back-icon")],
                          1
                        ),
                        _c("div", { staticStyle: { height: "25px" } }, [
                          _vm._v(_vm._s(_vm.$t("vue.back").toUpperCase())),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c("div", { staticClass: "flex flex-row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "dialog-consent-language flex justify-center items-center",
                      class:
                        _vm.selectedLanguage === "de"
                          ? "dialog-consent-language-active"
                          : "dialog-consent-language-inactive",
                      on: {
                        click: function ($event) {
                          _vm.selectedLanguage = "de"
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("vue.german")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dialog-consent-language flex justify-center items-center",
                      class:
                        _vm.selectedLanguage === "en"
                          ? "dialog-consent-language-active"
                          : "dialog-consent-language-inactive",
                      on: {
                        click: function ($event) {
                          _vm.selectedLanguage = "en"
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("vue.english")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _c(
                  "scroll-view",
                  {
                    ref: "legalNoticeText",
                    staticClass: "dialog-content-scroll-area",
                  },
                  [
                    _vm.selectedLanguage === "de"
                      ? _c(
                          "div",
                          { key: _vm.key, attrs: { id: "dv-consent-de" } },
                          [
                            _c("div", { staticClass: "mb-5" }, [
                              _c(
                                "strong",
                                { staticStyle: { "font-size": "1.2em" } },
                                [_vm._v("1) Textbaustein Consent Tool ")]
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                "Let's Connect ist ein Live-Chat Tool, um einem Webseitenbesucher die Kontaktaufnahme mit einem Berater zu ermöglichen."
                              ),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _c("strong", [
                                _vm._v("Verarbeitendes Unternehmen"),
                              ]),
                              _c("br"),
                              _vm._v(
                                "\n              PATHADVICE International GmbH"
                              ),
                              _c("br"),
                              _vm._v("\n              Serlesweg 3"),
                              _c("br"),
                              _vm._v("\n              6161 Natters"),
                              _c("br"),
                              _vm._v(
                                "\n              Österreich\n            "
                              ),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _c("strong", [
                                _vm._v(
                                  "Datenschutzbeauftragter der verarbeitenden Firma"
                                ),
                              ]),
                              _c("br"),
                              _vm._v("\n              Michael Suitner; "),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://www.letsconnect.at/data-privacy",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "https://www.letsconnect.at/data-privacy"
                                  ),
                                ]
                              ),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _c("strong", [
                                _vm._v("Datenverarbeitungszwecke"),
                              ]),
                              _c("br"),
                              _vm._v(
                                "\n              Der Dienst des Anbieters wird zum Zweck der Online-Kundenkommunikation eingesetzt.\n            "
                              ),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _c("strong", [_vm._v("Genutzte Technologien")]),
                              _c("br"),
                              _vm._v(
                                "\n              Diese Liste enthält alle Technologien, mit denen dieser Dienst Daten sammelt. Typische Technologien sind Cookies und Pixel, die im Browser\n              platziert werden: Local Storage\n            "
                              ),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _c("strong", [_vm._v("Erhobene Daten")]),
                              _c("br"),
                              _vm._v(
                                "\n              Diese Liste enthält alle Daten, die bei der oder durch die Nutzung des Service gesammelt werden: "
                              ),
                              _c("br"),
                              _c("br"),
                              _vm._v("\n\n              Verwendetes Gerät"),
                              _c("br"),
                              _vm._v("\n              Browser Sprache"),
                              _c("br"),
                              _vm._v("\n              Browser Typ/- Version"),
                              _c("br"),
                              _vm._v("\n              Seiten URL"),
                              _c("br"),
                              _vm._v("\n              Geografischer Standort"),
                              _c("br"),
                              _vm._v(
                                "\n              Datum und Uhrzeit des Zugriffs "
                              ),
                              _c("br"),
                              _vm._v("\n              Referrer URL"),
                              _c("br"),
                              _vm._v("\n              Dauer des Zugriffs"),
                              _c("br"),
                              _vm._v(
                                "\n              Vom Webseiten-Besucher eingegebene Kontaktdaten\n            "
                              ),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _c("strong", [_vm._v("Rechtsgrundlage")]),
                              _c("br"),
                              _vm._v(
                                "\n              Im Folgenden wird die erforderliche Rechtsgrundlage für die Verarbeitung von Daten genannt:"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              Art. 6 Abs. 1 a) und f) DSGVO\n            "
                              ),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _c("strong", [_vm._v("Ort der Verarbeitung")]),
                              _c("br"),
                              _vm._v(
                                "\n              Europäische Union\n            "
                              ),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _c("strong", [_vm._v("Aufbewahrungsdauer")]),
                              _c("br"),
                              _vm._v(
                                "\n              Die Aufbewahrungsfrist ist die Zeitspanne, in der die gesammelten Daten für die Verarbeitung gespeichert werden. Nach der Beendigung der\n              Kundenkommunikation werden die Daten gelöscht, sobald sie für die angegebenen Verarbeitungszwecke nicht mehr benötigt werden. Die maximale\n              Speicherdauer beträgt 3 Jahre.\n            "
                              ),
                            ]),
                            _c("p", { staticClass: "mt-2 mb-2" }, [
                              _c("strong", [_vm._v("Datenempfänger")]),
                              _c("br"),
                              _vm._v(
                                "\n              Im Folgenden werden die Empfänger der erhobenen Daten aufgelistet: "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              PATHADVICE International GmbH, Serlesweg 3, 6161 Natters, Austria\n              "
                              ),
                              _c("br"),
                              _c("br"),
                              _vm._v(
                                "\n\n              Klicken Sie hier, um die Datenschutzbestimmungen des Datenverarbeiters zu lesen: "
                              ),
                              _c("br"),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://www.letsconnect.at/data-privacy",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "https://www.letsconnect.at/data-privacy"
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "mb-5" }, [
                              _c(
                                "strong",
                                { staticStyle: { "font-size": "1.2em" } },
                                [
                                  _vm._v(
                                    "2) Textbaustein Datenschutzerklärung (Consent Tool)"
                                  ),
                                ]
                              ),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _vm._v(
                                "\n              [Website-Betreiber] setzt auf dieser Internetseite den Let's Connect Live-Chat-Dienst der PATHADVICE International GmbH, Serlesweg 3, 6161\n              Natters, Österreich, (im Nachfolgenden \"Let's Connect\") ein, welche eine einfache, direkte Online-Kommunikation zwischen [Website-Betreiber] und\n              seinen Website Besuchern ermöglicht. Beim Betrieb von Let's Connect werden folgende Daten verarbeitet: Verwendetes Gerät, Browser Sprache,\n              Browser Typ/- Version, Seiten URL, geografischer Standort, Datum und Uhrzeit des Zugriffs, Referrer URL, Dauer des Zugriffs, vom Website\n              Besucher eingegebene Kontaktdaten. Die vorgenannten Daten werden ausschließlich für den Betrieb des Live-Chat-Dienstes sowie der Kommunikation\n              mit Website-Besuchern benutzt. Nähere Informationen zum Dienst findet der User unter\n              "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://www.letsconnect.at/data-privacy",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "https://www.letsconnect.at/data-privacy"
                                  ),
                                ]
                              ),
                              _vm._v(".\n            "),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _vm._v(
                                "\n              [Website-Betreiber] benutzt den Dienst von Let's Connect auf Basis der Einwilligung nach Art. 6 Abs. 1 a) DSGVO. Darüber hinaus erfolgt der\n              Einsatz von Let's Connect auch auf Basis des berechtigten Interesses nach Art. 6 Abs. 1 f) DSGVO. Mittels Let's Connect ist es\n              [Website-Betreiber] möglich, eine effiziente und sichere Online-Kommunikation für den interessierten Website Besucher bereitzustellen. Nach der\n              Beendigung der Kundenkommunikation werden die Daten gelöscht, sobald sie für die angegebenen Verarbeitungszwecke nicht mehr benötigt werden. Die\n              maximale Speicherdauer beträgt 3 Jahre.\n            "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.selectedLanguage === "en"
                      ? _c(
                          "div",
                          { key: _vm.key, attrs: { id: "dv-consent-en" } },
                          [
                            _c("div", { staticClass: "mb-5" }, [
                              _c(
                                "strong",
                                { staticStyle: { "font-size": "1.2em" } },
                                [_vm._v("1) Template Consent Tool ")]
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                "\n              Let's Connect is a live-chat service which enables a simple, direct online communication between [website operator] and its website visitors.\n            "
                              ),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _c("strong", [_vm._v("Processing Company ")]),
                              _c("br"),
                              _vm._v(
                                "\n              PATHADVICE International GmbH"
                              ),
                              _c("br"),
                              _vm._v("\n              Serlesweg 3"),
                              _c("br"),
                              _vm._v("\n              6161 Natters"),
                              _c("br"),
                              _vm._v("\n              Austria\n            "),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _c("strong", [
                                _vm._v(
                                  "Data security official of processing company "
                                ),
                              ]),
                              _c("br"),
                              _vm._v("\n              Michael Suitner: "),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://www.letsconnect.at/data-privacy",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "https://www.letsconnect.at/data-privacy"
                                  ),
                                ]
                              ),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _c("strong", [_vm._v("Reason of Processing")]),
                              _c("br"),
                              _vm._v(
                                "\n              The service is used for a direct online communication with website visitors.\n            "
                              ),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _c("strong", [_vm._v("Used Technologies ")]),
                              _c("br"),
                              _vm._v(
                                "\n              Local Storage\n            "
                              ),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _c("strong", [_vm._v("Used Data ")]),
                              _c("br"),
                              _vm._v(
                                "\n              Used device, browser language, browser type/version, page URL, geographical location, date and time of access, referrer URL, duration of access,\n              contact details entered by the website visitor\n            "
                              ),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _c("strong", [_vm._v("Legal Basis")]),
                              _c("br"),
                              _vm._v(
                                "\n              Art. 6 Abs. 1 a) und f) DSGVO\n            "
                              ),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _c("strong", [_vm._v("Place of Processing ")]),
                              _c("br"),
                              _vm._v(
                                "\n              European Union\n            "
                              ),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _c("strong", [_vm._v("Storage Time ")]),
                              _c("br"),
                              _vm._v(
                                "\n              After the termination of the customer communication, the data is deleted as soon as it is no longer needed for the specified processing\n              purposes. The maximum storage period is 3 years.\n            "
                              ),
                            ]),
                            _c("p", { staticClass: "mt-2 mb-2" }, [
                              _c("strong", [_vm._v("Data Receiver ")]),
                              _c("br"),
                              _vm._v(
                                "\n              PATHADVICE International GmbH, Serlesweg 3, 6161 Natters, Austria\n\n              "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://www.letsconnect.at/data-privacy",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "https://www.letsconnect.at/data-privacy"
                                  ),
                                ]
                              ),
                            ]),
                            _c("br"),
                            _c("br"),
                            _c("div", { staticClass: "mb-5" }, [
                              _c("p", [
                                _c(
                                  "strong",
                                  { staticStyle: { "font-size": "1.2em" } },
                                  [
                                    _vm._v(
                                      "2) Template Privacy Policy (Consent Tool)"
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _vm._v(
                                "\n              [Website operator] uses the live chat service Let's Connect of PATHADVICE International GmbH, Serlesweg 3, 6161 Natters, Austria, (hereinafter\n              referred to as \"Let's Connect\") on this website, which enables simple, direct online communication between [website operator] and its website\n              visitors. When operating Let's Connect, the following data is processed: Used device, browser language, browser type/version, page URL,\n              geographical location, date and time of access, referrer URL, duration of access, contact details entered by the website visitor; The afore\n              mentioned data is used exclusively for the operation of the live chat service and communication with website visitors. For more information see\n              "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://www.letsconnect.at/data-privacy",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "https://www.letsconnect.at/data-privacy"
                                  ),
                                ]
                              ),
                              _vm._v(".\n            "),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _vm._v(
                                "\n              [Website operator] uses the service of Let's Connect based on consent according to Art. 6 para. 1 a) DSGVO. In addition, the use of Let's\n              Connect is also based on legitimate interest according to Art. 6 para. 1 f) DSGVO. By means of Let's Connect, it is possible for [website\n              operator] to provide efficient and secure online communication for interested website visitors. After the termination of the customer\n              communication, the data is deleted as soon as it is no longer needed for the specified processing purposes. The maximum storage period is 3\n              years.\n            "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "dialog-consent-content p-5 dialog-consent-absolute",
            class:
              _vm.showConsentToolSelection || _vm.usingConsentTool
                ? "left-500"
                : "",
          },
          [
            _c(
              "div",
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-row items-center mb-2 cursor-pointer",
                    on: {
                      click: function ($event) {
                        return _vm.onBackToSelection()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex items-center justify-center" },
                      [
                        _c(
                          "div",
                          { staticClass: "mr-2" },
                          [_c("back-icon")],
                          1
                        ),
                        _c("div", { staticStyle: { height: "25px" } }, [
                          _vm._v(_vm._s(_vm.$t("vue.back").toUpperCase())),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c("div", { staticClass: "flex flex-row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "dialog-consent-language flex justify-center items-center",
                      class:
                        _vm.selectedLanguage === "de"
                          ? "dialog-consent-language-active"
                          : "dialog-consent-language-inactive",
                      on: {
                        click: function ($event) {
                          _vm.selectedLanguage = "de"
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("vue.german")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dialog-consent-language flex justify-center items-center",
                      class:
                        _vm.selectedLanguage === "en"
                          ? "dialog-consent-language-active"
                          : "dialog-consent-language-inactive",
                      on: {
                        click: function ($event) {
                          _vm.selectedLanguage = "en"
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("vue.english")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _c(
                  "scroll-view",
                  { staticClass: "dialog-content-scroll-area" },
                  [
                    _vm.selectedLanguage === "de"
                      ? _c(
                          "div",
                          { key: _vm.key, attrs: { id: "dv-no-consent-de" } },
                          [
                            _c("div", { staticClass: "mb-5" }, [
                              _c(
                                "strong",
                                { staticStyle: { "font-size": "1.2em" } },
                                [
                                  _vm._v(
                                    "Datenschutzerklärung Website Textbaustein Let's Connect "
                                  ),
                                ]
                              ),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _vm._v(
                                "\n              [Website-Betreiber] setzt auf dieser Internetseite den Let's Connect Live-Chat-Dienst der PATHADVICE International GmbH, Serlesweg 3, 6161\n              Natters, Österreich, (im Nachfolgenden \"Let's Connect\") ein, welche eine einfache, direkte Online-Kommunikation zwischen [Website-Betreiber] und\n              seinen Website Besuchern ermöglicht. Beim Betrieb von Let's Connect werden folgende Daten verarbeitet: Verwendetes Gerät, Browser Sprache,\n              Browser Typ/- Version, Seiten URL, geografischer Standort, Datum und Uhrzeit des Zugriffs, Referrer URL, Dauer des Zugriffs, vom Website\n              Besucher eingegebene Kontaktdaten. Die vorgenannten Daten werden ausschließlich für den Betrieb des Live-Chat-Dienstes sowie der Kommunikation\n              mit Website-Besuchern benutzt. Nähere Informationen zum Dienst findet der User unter\n              "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://www.letsconnect.at/data-privacy",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "https://www.letsconnect.at/data-privacy"
                                  ),
                                ]
                              ),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _vm._v(
                                "\n              [Website-Betreiber] benutzt den Dienst von Let's Connect auf Basis des berechtigten Interesses nach Art. 6 Abs. 1 f) DSGVO. Mittels Let's\n              Connect ist es [Website-Betreiber] möglich, eine effiziente und sichere Online-Kommunikation für den interessierten Website Besucher\n              bereitzustellen. Nach der Beendigung der Kundenkommunikation werden die Daten gelöscht, sobald sie für die angegebenen Verarbeitungszwecke nicht\n              mehr benötigt werden. Die maximale Speicherdauer beträgt 3 Jahre.\n            "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.selectedLanguage === "en"
                      ? _c(
                          "div",
                          { key: _vm.key, attrs: { id: "dv-no-consent-en" } },
                          [
                            _c("div", { staticClass: "mb-5" }, [
                              _c(
                                "strong",
                                { staticStyle: { "font-size": "1.2em" } },
                                [
                                  _vm._v(
                                    "Template for Privacy Policy Let's Connect"
                                  ),
                                ]
                              ),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _vm._v(
                                "\n              Website operator] uses the live chat service Let's Connect of PATHADVICE International GmbH, Serlesweg 3, 6161 Natters, Austria, (hereinafter\n              referred to as \"Let's Connect\") on this website, which enables simple, direct online communication between [website operator] and its website\n              visitors. When operating Let's Connect, the following data is processed: Used device, browser language, browser type/version, page URL,\n              geographical location, date and time of access, referrer URL, duration of access, contact details entered by the website visitor; The afore\n              mentioned data is used exclusively for the operation of the live chat service and communication with website visitors. For more information see\n              "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://www.letsconnect.at/data-privacy",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "https://www.letsconnect.at/data-privacy"
                                  ),
                                ]
                              ),
                              _vm._v(".\n            "),
                            ]),
                            _c("p", { staticClass: "mt-2" }, [
                              _vm._v(
                                "\n              [Website operator] uses the service of Let's Connect based on legitimate interest according to Art. 6 para. 1 f) GDPR. By means of Let's\n              Connect, it is possible for [website operator] to provide efficient and secure online communication for interested website visitors. After the\n              termination of the customer communication, the data is deleted as soon as it is no longer needed for the specified processing purposes. The\n              maximum storage period is 3 years.\n            "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      !_vm.showConsentToolSelection
        ? _c(
            "vs-button",
            {
              key: _vm.key,
              staticClass: "float-right mt-5",
              attrs: {
                type: "border",
                size: "small",
                "icon-pack": "feather",
                icon: "icon-clipboard",
              },
              on: { click: _vm.copyTextToClipboard },
            },
            [_vm._v("\n    " + _vm._s(_vm.$t("vue.copy")) + "\n  ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }