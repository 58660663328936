<template>
  <div>
    <div class="vx-row w-full mt-5">
      <div class="vx-row w-full flex-box-column-start ml-5">
        <vs-col vs-type="flex" vs-w="12">
          <!-- This is now handled using app embedded blocks in shopify -->
          <!-- <div v-if="company.isShopifyAccount && company.shopifyStoreId" class="mt-2">
            <div v-if="shop && shop.dialogId === dialogId && dialog.scriptTagId">
              <shopify-successfully-integrated width="186" height="31" />
            </div>
          </div> -->
        </vs-col>

        <vs-col vs-type="flex" vs-w="12">
          <label>{{ $t('inputs.giveYourdialogName') }}</label>
        </vs-col>
        <vs-col vs-w="12">
          <div class="flex-box-row-start">
            <vs-input
              class="w-full mr-2"
              name="dialog-name"
              v-model="dialogName"
              v-validate.immediate="`required|min:3|isValidDialogName:${JSON.stringify({ oldVal: oldDialogName, dialogs: dialogNames })}`"
              style="display: inline-block; max-width: 520px"
            />

            <vx-tooltip :text="$t('info.dialogName')" position="top">
              <img :src="infoImg" />
            </vx-tooltip>
          </div>
          <span class="text-danger text-sm">{{ errors.first('dialog-name') }}</span>
        </vs-col>
      </div>
    </div>

    <div class="vx-row w-full mt-5">
      <div class="vx-row w-full flex-box-column-start ml-5">
        <vs-col vs-type="flex" vs-w="12">
          <label>{{ $t('inputs.setDisplayCompanyName') }}</label>
        </vs-col>
        <vs-col vs-w="12">
          <div class="flex-box-row-start">
            <vs-input
              class="w-full mr-2"
              name="dialog-company-display-name"
              v-validate.immediate="'max:50'"
              v-model="companyDisplayName"
              style="display: inline-block; max-width: 520px"
            />

            <vx-tooltip :text="$t('info.setDisplayCompanyName')" position="top">
              <img :src="infoImg" />
            </vx-tooltip>
          </div>
          <span class="text-danger text-sm">{{ errors.first('dialog-company-display-name') }}</span>
        </vs-col>
      </div>
    </div>

    <div class="vx-row w-full mt-5">
      <div class="vx-row w-full flex-box-column-start ml-5">
        <vs-col vs-w="12" class="language-selection-select">
          {{ $t('vue.selectLanguageText') }}
        </vs-col>
        <vs-col vs-w="12">
          <div class="flex-box-row-start">
            <v-select
              name="language-select"
              class="language-selection-select mr-2 mb-4 md:mb-0 w-full"
              style="display: inline-block; max-width: 520px"
              :options="webconnectSupportedLanguages"
              :placeholder="$t('vue.selectLanguage')"
              label="lnFullText"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="selectedLanguage"
              v-validate="'required'"
              validate-on="blur"
            >
              <template v-slot:option="option">
                <div>
                  {{ option.lnFullText }}
                </div>
              </template>
            </v-select>
            <vx-tooltip :text="$t('info.languageSelection')" position="top">
              <img :src="infoImg" />
            </vx-tooltip>
          </div>
        </vs-col>
      </div>
    </div>

    <div class="vx-row w-full mt-5">
      <div class="vx-row w-full flex-box-column-start ml-5">
        <vs-col vs-type="flex" vs-w="12">
          <label>{{ $t('ratingScreen.widgetUseSelectionLabel') }}</label>
        </vs-col>

        <vs-col vs-w="12">
          <div class="flex-box-row-start">
            <v-select
              name="widget-use"
              class="rating-screen-select mb-4 md:mb-0 w-full"
              style="display: inline-block; max-width: 520px"
              :options="widgetUses"
              :reduce="(uses) => uses.value"
              :placeholder="$t('ratingScreen.placeholderWidgetUse')"
              label="label"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="widgetUse"
              v-validate="'required'"
              validate-on="blur"
            >
              <template v-slot:option="option">
                <div>
                  {{ option.label }}
                </div>
              </template>
            </v-select>
          </div>
          <span class="text-danger text-sm">{{ errors.first('widget-use') }}</span>
        </vs-col>
      </div>
    </div>

    <div class="vx-row w-full mt-5">
      <div class="vx-row w-full flex-box-column-start ml-5">
        <vs-col vs-type="flex" vs-w="12">
          <label>{{ $t('vue.tryoutWidgetText') }}</label>
        </vs-col>
        <vs-col vs-type="flex" vs-w="12">
          <vs-button type="border" icon-pack="feather" class="mt-2 mr-2 mb-2" @click="testYourWidget">
            {{ $t('vue.testYourWidget') }}
          </vs-button>
          <vs-button type="border" class="mt-2 mr-2 mb-2" v-clipboard:copy="testUrl" v-clipboard:success="onCopy" v-clipboard:error="onError">
            <div class="flex-center">
              <div style="width: 10px; height: 10px; background: #3b86f7; border-radius: 10px; margin-right: 10px"></div>
              <div>{{ $t('vue.testWebsiteUrlCopy') }}</div>
            </div>
          </vs-button>
        </vs-col>
      </div>
    </div>
    <div class="vx-row w-full mt-5">
      <div class="vx-row w-full flex-box-column-start ml-5">
        <vs-col vs-type="flex" vs-w="12">
          <div class="step-headline">
            {{ $t('vue.websiteIntegration') }}
          </div>
        </vs-col>
      </div>
    </div>

    <div class="vx-row w-full"></div>

    <website-integration :dialogId="dialogId" :infoImg="infoImg" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import vSelect from 'vue-select'
import WebsiteIntegration from './components/WebsiteIntegration.vue'
//import ShopifySuccessfullyIntegrated from '@/components/icons/ShopifySuccessfullyIntegrated.vue'

export default {
  components: {
    WebsiteIntegration,
    vSelect
    //ShopifySuccessfullyIntegrated
  },
  props: {
    infoImg: {
      type: String,
      required: true
    },
    dialogId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      dialogs: 'dialogs',
      dialog: 'dialog',
      company: 'company',
      selectedLanguageForWebConnect: 'widgetTranslation/selectedLanguageForWebConnect',
      hasWidgetTranslationUpdated: 'widgetTranslation/hasWidgetTranslationUpdated'
    }),
    dialogNames() {
      if (!this.dialogs) {
        return []
      }
      return this.dialogs.map((x) => {
        return x.name.replaceAll('|', '-')
      })
    },
    webconnectSupportedLanguages() {
      const languages = [...this.languages]
      languages.push({ code: 'en', lnFullText: 'English', lnText: 'ENG' })
      return languages
    }
  },
  data() {
    return {
      content: '',
      testUrl: '',
      integrationSuccess: null,
      showDataProtectionPopup: false,
      oldDialogName: this.dialog && this.dialog.name ? this.dialog.name : '',
      widgetUses: [
        { label: 'Sales', value: 'sales' },
        { label: 'Support', value: 'support' }
      ],
      selectedLanguage: { code: 'en', lnFullText: 'English', lnText: 'ENG' },
      widgetUse: null,
      dialogName: null,
      companyDisplayName: null
    }
  },

  watch: {
    async 'selectedLanguage.code'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getTranslation()
      }
    },
    widgetUse() {
      this.updateDialogPartial({ widgetUse: this.widgetUse })
    },
    dialogName() {
      this.updateDialogPartial({ name: this.dialogName })
    },
    companyDisplayName() {
      this.updateDialogPartial({ companyDisplayName: this.companyDisplayName })
    }
  },

  mounted() {
    this.oldDialogName = this.dialog && this.dialog.name ? this.dialog.name : ''
    this.testUrl = `${this.getCorrectLocationUrl(location.origin)}/try-out/pathadvice.html?id=${this.dialogId}`
    this.selectedLanguage = this.selectedLanguageForWebConnect

    if (this.dialog) {
      this.widgetUse = this.dialog.widgetUse
      this.dialogName = this.dialog.name
      this.companyDisplayName = this.dialog.companyDisplayName
    }

    this.getTranslation()
  },
  methods: {
    ...mapActions({
      updateDialogPartial: 'updateDialogPartial',
      setHasWidgetTranslationUpdated: 'widgetTranslation/setHasWidgetTranslationUpdated',
      setWidgetTranslation: 'widgetTranslation/setWidgetTranslation',
      setLoadedWidgetTranslation: 'widgetTranslation/setLoadedWidgetTranslation',
      setDefaultWidgetTranslation: 'widgetTranslation/setDefaultWidgetTranslation',
      setSelectedLanguageForWebConnect: 'widgetTranslation/setSelectedLanguageForWebConnect'
    }),
    async getTranslation() {
      this.setSelectedLanguageForWebConnect(this.selectedLanguage)

      const languageRef = await this.$db
        .collection('translations')
        .doc(this.activeUserInfo.company)
        .collection('web-connect')
        .doc(this.dialogId)
        .collection('languages')
        .doc(this.selectedLanguage.code)
        .get()

      const translation = languageRef.data()

      if (translation && translation.webConnect) {
        this.setWidgetTranslation(translation.webConnect)
        this.setLoadedWidgetTranslation(Math.random().toString(36).substring(2, 15))
        this.setHasWidgetTranslationUpdated(false)
      } else {
        this.setDefaultWidgetTranslation(this.selectedLanguageForWebConnect.code)
        this.setLoadedWidgetTranslation(Math.random().toString(36).substring(2, 15))
        this.setHasWidgetTranslationUpdated(false)
      }
    },
    getCorrectLocationUrl(url) {
      return url && url.includes('capacitor') ? this.$config.hostUrl : location.origin
    },
    testYourWidget() {
      window.open(this.testUrl, '_blank')
    },
    onCopy() {
      this.$vs.notify({
        title: 'Success',
        text: 'Text copied successfully',
        color: 'success',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-check-circle'
      })
    },
    onError() {
      this.$vs.notify({
        title: 'Failed',
        text: 'Error in copying text',
        color: 'danger',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-alert-circle'
      })
    }
  }
}
</script>

<style lang="scss">
.language-selection-select {
  .vs__dropdown-toggle {
    background-color: rgba(var(--vs-primary), 1) !important;
    cursor: pointer;
  }
  .vs__selected,
  .vs__search,
  .vs__actions {
    color: #fff !important;
  }
  font-size: 16px;
  margin-bottom: 5px;
}
.language-javascript {
  max-width: 600px;
}
.step-headline {
  color: #262629;
  font-weight: 600;
  font-size: 18px;
}

.rating-screen-select .vs__dropdown-toggle .vs__search {
  color: #cccccc;
}
</style>
