<template>
  <div class="configure-dialog-step-two flex-box-column-start" :key="tabKey">
    <div class="vx-row w-full mb-5">
      <vs-col class="flex-box-row-start w-full" vs-w="12">
        <vs-button class="mr-2" color="primary" :type="dialog.design === 'pathadvice' ? 'filled' : 'border'" @click="changeDialogDesign('pathadvice')">
          {{ $t('vue.standardMode') }}
        </vs-button>
        <!-- DEVELOPER MODDE -->
        <vs-button class="ml-2" color="primary" :type="dialog.design === 'custom' ? 'filled' : 'border'" @click="changeDialogDesign('custom')">
          {{ $t('vue.developerMode') }}
        </vs-button>
      </vs-col>
    </div>

    <template v-if="dialog.design === 'custom'">
      <div class="vx-row w-full flex-box-column-start">
        <vs-col vs-type="flex" vs-w="12">
          <label>{{ $t('vue.embedCustomDesignText') }}</label>
        </vs-col>
        <vs-col vs-w="12">
          <div class="flex-box-row-start">
            <prism class="rounded-lg mr-2" language="javascript">{{ customContent }}</prism>
            <vx-tooltip :text="$t('info.customDesign')" position="top">
              <img :src="infoImg" />
            </vx-tooltip>
          </div>
        </vs-col>
        <vs-col vs-w="12">
          <vs-button
            type="border"
            size="small"
            icon-pack="feather"
            icon="icon-clipboard"
            v-clipboard:copy="customContent"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ $t('vue.copy') }}
          </vs-button>
        </vs-col>
      </div>
    </template>

    <template v-if="dialog.design === 'pathadvice'">
      <div class="vx-row w-full">
        <vs-col class="flex-box-row-start w-full" vs-w="12">
          <div class="headingfont">{{ $t('vue.dialogDisplayType') }}</div>
          <div class="ml-2">
            <vx-tooltip :text="$t('info.dialogDisplayType')" position="top">
              <img :src="infoImg" />
            </vx-tooltip>
          </div>
        </vs-col>
      </div>
      <div class="flex-row-start vx-row w-full mt-5">
        <div class="vx-col w-full mt-2" :class="`lg:w-${leftScreenVSWidth}/12`">
          <div class="flex-box-column-start align-items-start">
            <div>
              <div class="mb-3 headingfont inline-block pt-0">
                {{ $t('vue.preview') }}
              </div>
            </div>
            <!-- PREVIEW DIALOG -->
            <preview-dialog
              :dialog="dialog"
              :buttonContainerMinWidth="buttonContainerMinWidth"
              :embedVideoURL="embedVideoURL"
              :imageURL="imageURL"
              :smallButtonImage="smallButtonImage"
            />
          </div>
        </div>
        <div class="vx-col w-full mt-5" :class="`lg:w-${rightScreenVSWidth}/12`">
          <display-options id="s2" :infoImg="infoImg" :dialogId="dialogId" />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

import Prism from 'vue-prism-component'
import PreviewDialog from '../../../components/dialog/PreviewDialog.vue'
import DisplayOptions from '../../../components/dialog/DisplayOptions.vue'

export default {
  components: {
    Prism,
    PreviewDialog,
    DisplayOptions
  },
  props: {
    infoImg: {
      type: String,
      required: true
    },
    dialogId: {
      type: String,
      required: true
    },
    leftScreenVSWidth: {
      type: Number,
      required: true
    },
    rightScreenVSWidth: {
      type: Number,
      required: true
    },
    buttonContainerMinWidth: {
      type: Number,
      required: true
    },
    videoClientHeight: {
      type: Number,
      required: true
    },
    videoClientWidth: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      uploadTask: false,
      customContent: '<button onclick="pathadvice.connect()">Call Agent</button>',
      tabKey: Math.random().toString(36).substring(2, 15),

      defaultImageURL: require('@/assets/images/dialog/contact-form-background.jpeg'),

      imageURL: require('@/assets/images/dialog/contact-form-background.jpeg'),

      smallButtonImage: null,
      defaultSmallButtonImage: require('@/assets/images/dialog/default-small-button-image.svg'),
      colors: [
        { name: 'primary', value: '#3b86f7' },
        { name: 'secondary', value: '#12598d' },
        { name: 'success', value: '#28C76F' },
        { name: 'danger', value: '#EA5455' },
        { name: 'warning', value: '#FF9F43' },
        { name: 'dark', value: '#1E1E1E' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      dialog: 'dialog'
    }),
    defaultVideoURL() {
      return '/Mann-Winken-SD.mp4'
    },
    embedVideoURL() {
      return this.defaultVideoURL
    },

    buttonCardContainer() {
      return {
        backgroundImage: `url('${this.buttonContainerBackground}')`,
        backgroundRepeat: 'no-repeat',
        borderRadius: '6px',
        width: '112.03px',
        height: '71.97',
        backgroundSize: '112.03px 71.97px'
      }
    },
    buttonContainerBackground() {
      if (!this.dialog.showVideo) {
        return this.imageURL
      } else {
        return ''
      }
    }
  },
  watch: {
    'dialog.smallButtonImage'() {
      if (this.dialog && this.dialog.smallButtonImage) {
        this.smallButtonImage = this.dialog.smallButtonImage
      } else {
        this.smallButtonImage = this.defaultSmallButtonImage
      }
      this.$emit('dialog-updated', this.dialog)
    }
  },
  mounted() {
    const vm = this

    if (vm.dialog && vm.dialog.smallButtonImage) {
      vm.smallButtonImage = vm.dialog.smallButtonImage
    } else {
      vm.smallButtonImage = vm.defaultSmallButtonImage
    }
    this.tabKey = Math.random().toString(36).substring(2, 15)
  },
  methods: {
    ...mapActions({
      updateDialogPartial: 'updateDialogPartial'
    }),
    onCopy() {
      this.$vs.notify({
        title: 'Success',
        text: 'Text copied successfully',
        color: 'success',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-check-circle'
      })
    },
    onError() {
      this.$vs.notify({
        title: 'Failed',
        text: 'Error in copying text',
        color: 'danger',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-alert-circle'
      })
    },
    changeDialogDesign(design) {
      const _update = {
        design
      }
      if (design === 'pathadvice') {
        _update.type = 'button-card'
      } else {
        _update.type = 'custom'
      }
      this.updateDialogPartial(_update)
      this.tabKey = Math.random().toString(36).substring(2, 15)
    }
  }
}
</script>

<style lang="scss">
.con-vs-popup.fullscreen .vs-popup {
  .vs-popup--header {
    display: none;
  }
  .vs-popup--content {
    height: calc(100vh - 100px);
  }
}
.croppa-container.croppa--has-target {
  border-radius: 10px;
}
.check-circle-icon {
  position: absolute;
  top: 15px;
  width: 50px;
  height: 50px;
  right: 20px;
  z-index: 999;
  border-radius: 6px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}
.check-circle-icon:hover {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  background-color: #026e02 !important;
  opacity: 0.6;
}
.configure-dialog-step-two {
  .check-circle-icon {
    position: absolute;
    top: 15px;
    width: 50px;
    height: 50px;
    right: 15px;
    z-index: 999;
    border-radius: 6px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  }
  .check-circle-icon:hover {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    background-color: #026e02 !important;
    opacity: 0.6;
  }
}
</style>
