var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vx-card", [
    _c(
      "div",
      { staticClass: "configure-dialog" },
      [
        _vm.isTestsPresent && _vm.isManyTestsEnabled && _vm.isLoadingComplete
          ? _c(
              "testing-banner",
              {
                staticClass: "configure-dialog__testing-banner",
                class: {
                  _danger: !_vm.isShowTestActiveBanner,
                  _default: _vm.isShowTestActiveBanner,
                },
                on: { "on-action-click": _vm.onTestingBannerActionClick },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "link",
                      fn: function () {
                        return [
                          _vm._v(" " + _vm._s(_vm.$t("routes.abtesting"))),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3033436123
                ),
              },
              [[_vm._v(_vm._s(_vm.testBannerMessage))]],
              2
            )
          : _vm._e(),
        _vm.dialog && _vm.dialogId
          ? _c(
              "form-wizard",
              {
                ref: "formWizard",
                attrs: {
                  color: "rgba(var(--vs-primary), 1)",
                  title: "",
                  subtitle: "",
                  nextButtonText: _vm.$t("vue.next"),
                  backButtonText: _vm.$t("vue.back"),
                  finishButtonText: _vm.$t("inputs.submit"),
                  shape: "circle",
                },
                on: {
                  "on-complete": _vm.onComplete,
                  "on-change": _vm.onStepChanged,
                },
              },
              [
                _c(
                  "tab-content",
                  {
                    staticClass: "mb-5",
                    attrs: {
                      title: _vm.$t("vue.prepareWebsite"),
                      icon: "feather icon-home",
                      "before-change": _vm.beforeStep2,
                      lazy: true,
                    },
                  },
                  [
                    _c("step-one", {
                      attrs: {
                        "info-img": _vm.infoImg,
                        "dialog-id": _vm.dialogId,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "tab-content",
                  {
                    staticClass: "tab2 mb-5",
                    attrs: {
                      title: _vm.$t("vue.displayOptions"),
                      icon: "feather icon-briefcase",
                      "before-change": _vm.validateStep2,
                      lazy: true,
                    },
                  },
                  [
                    _c("step-two", {
                      attrs: {
                        "info-img": _vm.infoImg,
                        "dialog-id": _vm.dialogId,
                        leftScreenVSWidth: _vm.leftScreenVSWidth,
                        rightScreenVSWidth: _vm.rightScreenVSWidth,
                        "button-container-min-width":
                          _vm.buttonContainerMinWidth,
                        "video-client-height": _vm.videoClientHeight,
                        "video-client-width": _vm.videoClientWidth,
                        "video-key": _vm.videoKey,
                      },
                      on: { "dialog-updated": _vm.onDialogUpdated },
                    }),
                  ],
                  1
                ),
                _vm.dialog.design === "pathadvice"
                  ? _c(
                      "tab-content",
                      {
                        staticClass: "mb-5",
                        attrs: {
                          title: _vm.$t("vue.textDialogtype"),
                          icon: "feather icon-image",
                          "before-change": _vm.validateStep3,
                        },
                      },
                      [
                        _c("step-three", {
                          attrs: {
                            "info-img": _vm.infoImg,
                            "dialog-id": _vm.dialogId,
                            lazy: true,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.dialog.design === "pathadvice"
                  ? _c(
                      "tab-content",
                      {
                        staticClass: "mb-5",
                        attrs: {
                          title: _vm.$t("vue.requestOptions"),
                          icon: "feather icon-phone",
                          lazy: true,
                        },
                      },
                      [
                        _c("step-four", {
                          attrs: {
                            "info-img": _vm.infoImg,
                            dialogId: _vm.dialogId,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.dialog.design === "pathadvice"
                  ? _c(
                      "tab-content",
                      {
                        staticClass: "mb-5",
                        attrs: {
                          title: _vm.$t("vue.endScreen"),
                          icon: "feather icon-star",
                          lazy: true,
                          "before-change": _vm.validateStep5,
                        },
                      },
                      [
                        _c("step-five", {
                          attrs: { "info-img": _vm.infoImg },
                          on: { "dialog-updated": _vm.onDialogUpdated },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.dialog.design === "pathadvice" &&
                !(!_vm.HAS_CONTACTFORM_ACCESS && _vm.isMobileApp)
                  ? _c(
                      "tab-content",
                      {
                        staticClass: "mb-5",
                        attrs: {
                          title: _vm.$t("vue.contactForm"),
                          icon: "feather icon-message-square",
                          lazy: true,
                        },
                      },
                      [_c("step-six", { attrs: { "info-img": _vm.infoImg } })],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }