<template>
  <div class="testing-banner">
    <div class="testing-banner__info">
      <svg class="testing-banner__info-icon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
          fill="white"
          stroke="#3B86F7"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M11 7V11" stroke="#3B86F7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11 15H11.01" stroke="#3B86F7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <div class="testing-banner__text">
        <slot></slot>
      </div>
    </div>
    <a class="testing-banner__action" href="#" @click.prevent="$emit('on-action-click')">
      <span>
        <slot name="link"></slot>
      </span>
      <svg class="testing-banner__action-icon" width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 6.10547L20.4319 6.10547" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M15 1.00085L21 6.10394L15 11.207" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  name: 'TestingBanner'
}
</script>

<style lang="scss" scoped>
.testing-banner {
  padding: 12px 22px;
  min-height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #3b86f7;
  &._default {
    background: #3b86f7;
  }
  &._danger {
    background: #f05541;
    .testing-banner__info-icon {
      path {
        stroke: #f05541;
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    margin-right: 10px;
    &-icon {
      min-width: 20px;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    margin-left: 20px;
  }

  &__action {
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    background: #f0a941;
    min-height: 30px;
    padding: 3px 16px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s linear;
    min-width: 143px;
    &:hover {
      box-shadow: 0 8px 25px -8px #f0a941;
      transition: all 0.2s linear;
    }
    span {
      margin-right: 11px;
    }
  }
}
</style>
