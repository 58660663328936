import { render, staticRenderFns } from "./StepSix.vue?vue&type=template&id=77573454&scoped=true&"
import script from "./StepSix.vue?vue&type=script&lang=js&"
export * from "./StepSix.vue?vue&type=script&lang=js&"
import style0 from "./StepSix.vue?vue&type=style&index=0&id=77573454&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77573454",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/alexbilyk/Projects-Apps/pa/pathadvice-connect/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('77573454')) {
      api.createRecord('77573454', component.options)
    } else {
      api.reload('77573454', component.options)
    }
    module.hot.accept("./StepSix.vue?vue&type=template&id=77573454&scoped=true&", function () {
      api.rerender('77573454', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/dialog/StepSix.vue"
export default component.exports