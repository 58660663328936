var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "connect-modal",
        {
          attrs: {
            title: _vm.$t("vue.dataProtectionHeadline"),
            width: "600px",
            show: _vm.showDataProtectionPopup,
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "margin-left": "auto",
                width: "15px",
                height: "15px",
                cursor: "pointer",
              },
              on: {
                click: function ($event) {
                  _vm.showDataProtectionPopup = false
                },
              },
            },
            [
              _c("close-circle-icon", {
                staticClass: "pa-bk-secondary",
                attrs: { "show-circle": false, width: 14, height: 14 },
              }),
            ],
            1
          ),
          _c("legal-notice", { staticClass: "wi-legacy-notice" }),
        ],
        1
      ),
      _c("div", { staticClass: "step-one flex-box-column-start mt-5" }, [
        _vm.IS_SHOPIFY_ACCOUNT
          ? _c(
              "div",
              { staticClass: "vx-row w-full" },
              [
                _c("vs-col", { staticClass: "mr-12", attrs: { "vs-w": "1" } }, [
                  _c("img", {
                    attrs: {
                      width: "39px",
                      height: "39px",
                      src: require("@/assets/images/elements/first.svg"),
                    },
                  }),
                ]),
                _c("vs-col", { attrs: { "vs-type": "flex", "vs-w": "9" } }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-row w-full flex-box-column-start integration-steps items-start",
                    },
                    [
                      _c("ol", [
                        _c("li", [
                          _c("div", { staticClass: "flex flex-column" }, [
                            _c("div", [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("shopify.copyWidgetId"))),
                              ]),
                            ]),
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "flex-box-row-start" },
                                [
                                  _c(
                                    "prism",
                                    {
                                      staticClass: "rounded-lg mr-2",
                                      attrs: { language: "javascript" },
                                    },
                                    [_vm._v(_vm._s(_vm.dialogId))]
                                  ),
                                  _c(
                                    "vx-tooltip",
                                    {
                                      attrs: {
                                        text: _vm.$t("shopify.codeInfo"),
                                        position: "top",
                                      },
                                    },
                                    [_c("img", { attrs: { src: _vm.infoImg } })]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              [
                                _c(
                                  "vs-button",
                                  {
                                    directives: [
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:copy",
                                        value: _vm.dialogId,
                                        expression: "dialogId",
                                        arg: "copy",
                                      },
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:success",
                                        value: _vm.onCopy,
                                        expression: "onCopy",
                                        arg: "success",
                                      },
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:error",
                                        value: _vm.onError,
                                        expression: "onError",
                                        arg: "error",
                                      },
                                    ],
                                    attrs: {
                                      type: "border",
                                      size: "small",
                                      "icon-pack": "feather",
                                      icon: "icon-clipboard",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.$t("vue.copy")) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("li", [
                          _c("div", { staticClass: "flex flex-column" }, [
                            _c("div", [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("shopify.pasteWidgetInShopifyStore")
                                  )
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              [
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "mt-2 mr-2 mb-2",
                                    attrs: { type: "border" },
                                    on: { click: _vm.goToShopifyStore },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t("shopify.goToShopifyStore")
                                        ) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("div", [
                              _c("img", {
                                attrs: {
                                  width: "800px",
                                  height: "328px",
                                  src: require("@/assets/images/shopify/shopify-store.png"),
                                },
                              }),
                            ]),
                          ]),
                        ]),
                        _c("li", [
                          _c("div", { staticClass: "flex flex-column" }, [
                            _c("div", [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("shopify.clickOnSave"))),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "vx-row w-full" },
              [
                _c("vs-col", { staticClass: "mr-12", attrs: { "vs-w": "1" } }, [
                  _c("img", {
                    attrs: {
                      width: "39px",
                      height: "39px",
                      src: require("@/assets/images/elements/first.svg"),
                    },
                  }),
                ]),
                _c("vs-col", { attrs: { "vs-type": "flex", "vs-w": "9" } }, [
                  _c(
                    "div",
                    { staticClass: "vx-row w-full flex-box-column-start" },
                    [
                      _c(
                        "vs-col",
                        { attrs: { "vs-type": "flex", "vs-w": "12" } },
                        [
                          _c("label", [
                            _vm._v(_vm._s(_vm.$t("vue.embedCodeText"))),
                          ]),
                        ]
                      ),
                      _c("vs-col", { attrs: { "vs-w": "12" } }, [
                        _c(
                          "div",
                          { staticClass: "flex-box-row-start" },
                          [
                            _c(
                              "prism",
                              {
                                staticClass: "rounded-lg mr-2",
                                attrs: { language: "javascript" },
                              },
                              [_vm._v(_vm._s(_vm.content))]
                            ),
                            _c(
                              "vx-tooltip",
                              {
                                attrs: {
                                  text: _vm.$t("info.code"),
                                  position: "top",
                                },
                              },
                              [_c("img", { attrs: { src: _vm.infoImg } })]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "vs-col",
                        { attrs: { "vs-w": "12" } },
                        [
                          _c(
                            "vs-button",
                            {
                              directives: [
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:copy",
                                  value: _vm.content,
                                  expression: "content",
                                  arg: "copy",
                                },
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:success",
                                  value: _vm.onCopy,
                                  expression: "onCopy",
                                  arg: "success",
                                },
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:error",
                                  value: _vm.onError,
                                  expression: "onError",
                                  arg: "error",
                                },
                              ],
                              attrs: {
                                type: "border",
                                size: "small",
                                "icon-pack": "feather",
                                icon: "icon-clipboard",
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("vue.copy")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
        _c(
          "div",
          { staticClass: "vx-row w-full mt-5" },
          [
            _c("vs-col", { staticClass: "mr-12", attrs: { "vs-w": "1" } }, [
              _c("img", {
                attrs: {
                  width: "39px",
                  height: "39px",
                  src: require("@/assets/images/elements/second.svg"),
                },
              }),
            ]),
            _c("vs-col", { attrs: { "vs-type": "flex", "vs-w": "9" } }, [
              _c(
                "div",
                { staticClass: "vx-row w-full flex-box-column-start" },
                [
                  _c("vs-col", { attrs: { "vs-type": "flex", "vs-w": "12" } }, [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("vue.testIntegrationText"))),
                    ]),
                  ]),
                  _c("vs-col", { attrs: { "vs-w": "12" } }, [
                    _c(
                      "div",
                      { staticClass: "flex-box-row-start" },
                      [
                        _c(
                          "vs-button",
                          {
                            staticClass: "mt-2 mr-2 mb-2",
                            attrs: { type: "border" },
                            on: { click: _vm.testIntegration },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("vue.testIntegration")) +
                                "\n              "
                            ),
                          ]
                        ),
                        _c(
                          "vx-tooltip",
                          {
                            attrs: {
                              text: _vm.$t("info.testingIntegration"),
                              position: "top",
                            },
                          },
                          [_c("img", { attrs: { src: _vm.infoImg } })]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("vs-col", { attrs: { "vs-w": "12" } }, [
                    _c("div", { staticClass: "flex-box-row-start" }, [
                      _vm.integrationSuccess == 1 || _vm.dialog.codeIntegrated
                        ? _c("img", {
                            staticClass: "mr-2",
                            attrs: {
                              src: require("@/assets/images/elements/ok.png"),
                              width: "25",
                              height: "25",
                            },
                          })
                        : _vm._e(),
                      _vm.integrationSuccess == 0
                        ? _c("img", {
                            staticClass: "mr-2",
                            attrs: {
                              src: require("@/assets/images/elements/no.png"),
                              width: "25",
                              height: "25",
                            },
                          })
                        : _vm._e(),
                      _vm.integrationSuccess == 1 || _vm.dialog.codeIntegrated
                        ? _c("div", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(_vm.$t("vue.successfulIntegration"))),
                          ])
                        : _vm._e(),
                      _vm.integrationSuccess == 0
                        ? _c("div", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(_vm.$t("vue.failedIntegration"))),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-row w-full mt-5" },
          [
            _c("vs-col", { staticClass: "mr-12", attrs: { "vs-w": "1" } }, [
              _c("img", {
                attrs: {
                  width: "39px",
                  height: "39px",
                  src: require("@/assets/images/elements/third.svg"),
                },
              }),
            ]),
            _c("vs-col", { attrs: { "vs-type": "flex", "vs-w": "9" } }, [
              _c(
                "div",
                { staticClass: "vx-row w-full flex-box-column-start" },
                [
                  _c("vs-col", { attrs: { "vs-type": "flex", "vs-w": "12" } }, [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("vue.privacyPolicyText"))),
                    ]),
                  ]),
                  _c("vs-col", { attrs: { "vs-w": "12" } }, [
                    _c(
                      "div",
                      { staticClass: "flex-box-row-start" },
                      [
                        _c(
                          "vs-button",
                          {
                            staticClass: "mt-2 mr-2 mb-2",
                            attrs: {
                              type: "border",
                              "icon-pack": "feather",
                              icon: "icon-lock",
                            },
                            on: {
                              click: function ($event) {
                                _vm.showDataProtectionPopup = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("vue.dataProtectionTextButton")) +
                                "\n              "
                            ),
                          ]
                        ),
                        _c(
                          "vx-tooltip",
                          {
                            attrs: {
                              text: _vm.$t("info.privacyPolicy"),
                              position: "top",
                            },
                          },
                          [_c("img", { attrs: { src: _vm.infoImg } })]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }