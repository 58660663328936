<template>
  <div class="configure-dialog-step-four">
    <connect-modal :show="showAddEditRoute" :width="'450px'" :height="'250px'" bkcolor="#F7F7F7">
      <div class="modal-close" style="margin-left: auto" @click="showAddEditRoute = false">
        <close-circle-icon class="pa-bk-secondary" :show-circle="false" :width="14" :height="14"></close-circle-icon>
      </div>

      <div class="route-options-modal flex-column p-5">
        <div class="p-2 w-full">
          <vs-input class="text" type="text" v-model="routeDisplayText" :placeholder="$t('inputs.displayName')"></vs-input>
        </div>
        <div class="p-2 w-full">
          <v-select
            class="mb-4 md:mb-0 w-full"
            :placeholder="$t('vue.selectUserGroup')"
            :options="userGroupList"
            :searchable="false"
            :clearable="false"
            label="name"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="routeGroup"
          >
            <template v-slot:option="option">
              <div>
                {{ option.name }}
              </div>
            </template>

            <template #no-options>{{ '' }}</template>

            <template #list-footer>
              <div
                class="configure-dialog-step-four__select-link-message"
                @click="$router.push({ name: 'company-setup', params: { action: 'create-user-group' } })"
              >
                <UserIcon color="#3B86F7" class="configure-dialog-step-four__select-link-message__icon" :width="13" :height="13" />

                {{ $t('vue.createNewUserGroup') }}
              </div>
            </template>
          </v-select>
        </div>
        <div class="p-2 w-full">
          <vs-button block class="w-full" @click="addRoute"> Add </vs-button>
        </div>
      </div>
    </connect-modal>

    <div class="flex-box-column-start">
      <div class="vx-row w-full">
        <vs-col class="flex-box-row-start w-full" vs-w="12">
          <div class="headingfont mr-2">
            {{ $t('vue.communicationOptionsText') }}
          </div>
        </vs-col>
      </div>
    </div>

    <div class="request-options mt-4 flex flex-row">
      <form class="flex flex-column vx-col lg:w-6/12 w-full mt-2 mr-4">
        <div class="mt-4 flex flex-column w-full">
          <div class="step-headline mb-2">
            {{ $t('vue.activateRoutingScreen') }}
          </div>
          <div class="request-options--lightgray" v-html="$t('vue.activateRoutingScreenInfo')"></div>
        </div>
        <div class="flex-view">
          <div class="mt-4 flex items-center">
            <div class="mr-2">
              <div @click="enableRoutingScreenBeforeCommunicationOptions">
                <toggle-switch-icon :enabled="isShowRoutingScreen" :width="40" :height="40" />
              </div>
            </div>
            <div class="mr-2">{{ $t('vue.yes') }}</div>

            <div
              v-if="isShowRoutingScreen"
              class="flex-view-button p-3 w-full"
              :class="{ 'flex-view-button--disabled': dialog.routeCommunicationOptions && dialog.routeCommunicationOptions.length >= 6 }"
              @click.stop="addNewRoute()"
            >
              <div>
                <add-contact-icon
                  class="mr-2"
                  :width="20"
                  :height="20"
                  :color="dialog.routeCommunicationOptions && dialog.routeCommunicationOptions.length >= 6 ? '#b2b2b2' : '#ffffff'"
                />
              </div>
              <div class="text">{{ $t('vue.addRoute') }}</div>
            </div>
          </div>
        </div>

        <div v-if="isShowRoutingScreen" class="mt-4 flex w-full">
          <div class="mb-2">
            <strong>{{ $t('vue.routingScreenButtons') }}</strong>
          </div>
        </div>

        <div v-if="isShowRoutingScreen && dialog.routeCommunicationOptions" class="flex-view">
          <div v-for="(option, i) of dialog.routeCommunicationOptions" :key="i" class="flex-view-button flex-view-button--big relative">
            <div class="text">{{ option.displayText }}</div>
            <div class="request-options--delete-btn" @click="removeRouteCommunicationOption(i)">
              <close-circle-icon class="delete-btn-bck" :show-circle="false" :width="14" :height="14"></close-circle-icon>
            </div>
          </div>
        </div>

        <!-- <div v-if="dialog.isShowRoutingScreen" class="mt-4 flex items-center">
          <div class="mr-2">
            <div @click="showRoutingScreenPreview = !showRoutingScreenPreview">
              <toggle-switch-icon :enabled="showRoutingScreenPreview" :width="40" :height="40" />
            </div>
          </div>
          <div class="mr-2">{{ $t('vue.showPreview') }}</div>
        </div> -->

        <div class="mt-12 flex w-full">
          <div class="step-headline mb-2">
            {{ $t('vue.automatedChatResponseLabel') }}
          </div>
        </div>

        <div class="mt-4 flex items-center">
          <div class="mr-2">
            <div @click="toggleAutomatedChatResponse">
              <toggle-switch-icon :enabled="isAutomatedChatResponseEnabled" :width="40" :height="40" />
            </div>
          </div>
          <div class="mr-2">{{ $t('vue.yes') }}</div>
        </div>

        <div class="flex-col">
          <div v-if="!canUpdateTextFields" class="w-full bold">English</div>
          <div class="w-full">
            <vs-textarea
              counter="300"
              width="400px"
              height="100px"
              :label="$t('vue.automatedChatResponse')"
              :counter-danger.sync="counterDangerPopupText"
              v-model="automatedChatResponse"
            />
          </div>
          <div v-if="!canUpdateTextFields" class="w-full">
            <div class="w-full bold">{{ this.selectedLanguageForWebConnect.lnFullText }}</div>
            <div class="vs-col w-full">
              <vs-textarea
                counter="300"
                height="100px"
                :label="$t('vue.automatedChatResponse')"
                :counter-danger.sync="counterDangerPopupTextTranslation"
                v-model="tWebConnectAutomatedChatResponse"
              />
            </div>
          </div>
        </div>

        <div class="mt-12 flex w-full">
          <div class="step-headline mb-2">
            {{ $t('vue.communicationOptionsLabel') }}
          </div>
        </div>
        <div class="flex-view">
          <div
            class="flex-view-button"
            :class="{ 'flex-view-button--disabled': dialog.communicationOptions && !dialog.communicationOptions.chat }"
            @click="updateCommunicationOptions('chat')"
          >
            <div style="margin-right: 10px; height: 18px">
              <chat-icon :width="18" :height="18" :color="dialog.communicationOptions && !dialog.communicationOptions.chat ? '#b2b2b2' : '#ffffff'" />
            </div>
            <div class="text">{{ $t('vue.chat') }}</div>
          </div>
          <div
            class="flex-view-button"
            :class="{ 'flex-view-button--disabled': dialog.communicationOptions && !dialog.communicationOptions.audio }"
            @click="updateCommunicationOptions('audio')"
          >
            <div style="margin-right: 10px; height: 18px">
              <vertical-phone-icon
                :width="18"
                :height="18"
                :color="dialog.communicationOptions && !dialog.communicationOptions.audio ? '#b2b2b2' : '#ffffff'"
              />
            </div>
            <div class="text">{{ $t('vue.phone') }}</div>
          </div>
          <div
            class="flex-view-button"
            :class="{ 'flex-view-button--disabled': dialog.communicationOptions && !dialog.communicationOptions.video }"
            @click="updateCommunicationOptions('video')"
          >
            <div style="margin-right: 10px; height: 20px">
              <video-call-icon :width="20" :height="20" :color="dialog.communicationOptions && !dialog.communicationOptions.video ? '#b2b2b2' : '#ffffff'" />
            </div>
            <div class="text">{{ $t('vue.video') }}</div>
          </div>
        </div>

        <div class="mt-4 flex w-full">
          <div class="mb-2">
            <strong>{{ $t('vue.activateLeadFormBeforeConversation') }}</strong>
          </div>
        </div>
        <div class="flex-view">
          <div class="mt-4 flex items-center">
            <div class="mr-2">
              <div @click="enableLeadFormBeforeConversation">
                <toggle-switch-icon :enabled="dialog.isShowLeadFormBeforeConversation" :width="40" :height="40" />
              </div>
            </div>
            <div class="mr-2">{{ $t('vue.yes') }}</div>
          </div>
        </div>

        <template v-if="dialog.isShowLeadFormBeforeConversation">
          <div class="mt-4 flex w-full">
            <div class="mb-2">
              <strong>{{ $t('vue.enablePrivacyInformation') }}</strong>
            </div>
          </div>

          <div class="mt-4 flex items-center">
            <div class="mr-2">
              <div @click="togglePrivacyInformation">
                <toggle-switch-icon :enabled="dialog.leadForm.isPrivacyInformationEnabled" :width="40" :height="40" />
              </div>
            </div>
            <div class="mr-2">{{ $t('vue.yes') }}</div>
          </div>

          <div class="mt-4 mr-2 w-full" v-if="dialog.leadForm.isPrivacyInformationEnabled">
            <div v-if="!canUpdateTextFields" class="w-full bold">English</div>
            <quill-editor v-model="privacyInformationHtml" ref="quillEditorA" :options="editorOption"> </quill-editor>
          </div>
          <div class="mr-2 w-full" v-if="dialog.leadForm.isPrivacyInformationEnabled && !canUpdateTextFields">
            <div v-if="!canUpdateTextFields" class="w-full bold">{{ this.selectedLanguageForWebConnect.lnFullText }}</div>
            <quill-editor v-model="tWebConnectPrivacyInformationHtml" ref="quillEditorATranslation" :options="editorOption"> </quill-editor>
          </div>
        </template>
      </form>
      <div class="vx-col lg:w-6/12 w-full mt-2 ml-4">
        <div class="step-headline">
          {{ $t('vue.preview') }}
        </div>
        <div class="flex flex-preview">
          <welcome-screen-preview
            v-if="isShowRoutingScreen"
            id="request-options"
            :uploadType="dialog.useOneMediaForAllSteps ? 'allMedia' : 'welcomeScreenMedia'"
            :uploadSubtype="dialog.useOneMediaForAllSteps && typeof dialog.allMedia === 'object' && dialog.allMedia !== null ? 'welcomeScreenMedia' : ''"
            :dialog="dialog"
            :imageURL="defaultImageURL"
            :embedVideoURL="embedVideoURL"
            :showRoutingScreenPreview="true"
            :key="updateKey"
          />
          <welcome-screen-preview
            id="request-options"
            :uploadType="dialog.useOneMediaForAllSteps ? 'allMedia' : 'welcomeScreenMedia'"
            :uploadSubtype="dialog.useOneMediaForAllSteps && typeof dialog.allMedia === 'object' && dialog.allMedia !== null ? 'welcomeScreenMedia' : ''"
            :dialog="dialog"
            :imageURL="defaultImageURL"
            :embedVideoURL="embedVideoURL"
            :showRoutingScreenPreview="false"
            :key="updateKey2"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

import { mapGetters, mapActions } from 'vuex'

import ConnectModal from '@/components/ConnectModal.vue'

import ChatIcon from '@/components/icons/ChatIcon.vue'
import VerticalPhoneIcon from '@/components/icons/VerticalPhoneIcon.vue'
import VideoCallIcon from '@/components/icons/VideoCallIcon.vue'
import WelcomeScreenPreview from '@/components/dialog/media/WelcomeScreenPreview'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'
import AddContactIcon from '@/components/icons/AddContactIcon.vue'
import CloseCircleIcon from '@/components/icons/CloseCircleIcon.vue'
import UserIcon from '@/components/icons/UserIcon.vue'
import vSelect from 'vue-select'

export default {
  components: {
    quillEditor,
    ConnectModal,
    ChatIcon,
    VerticalPhoneIcon,
    VideoCallIcon,
    WelcomeScreenPreview,
    ToggleSwitchIcon,
    AddContactIcon,
    CloseCircleIcon,
    UserIcon,
    vSelect
  },
  props: {
    infoImg: {
      type: String,
      required: true
    },
    dialogId: {
      type: String,
      required: true
    }
  },

  watch: {
    isShowRoutingScreen() {
      this.updateDialogPartial({ isShowRoutingScreen: this.isShowRoutingScreen })
    },
    automatedChatResponse() {
      this.updateDialogPartial({ automatedChatResponse: this.automatedChatResponse })
    },
    privacyInformationHtml() {
      const leadForm = { ...this.dialog.leadForm }
      leadForm.privacyInformationHtml = this.privacyInformationHtml
      this.updateDialogPartial({ leadForm })
    },

    hasLoadedWidgetTranslation(newVal) {
      if (newVal) {
        this.loadTranslation()
      }
      this.$nextTick(() => {
        this.errors.clear()
        this.$validator.reset()
      })
    },
    tWebConnectAutomatedChatResponse() {
      if (this.tWebConnectAutomatedChatResponse !== this.selectedWidgetTranslation.automatedChatResponse) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ automatedChatResponse: this.tWebConnectAutomatedChatResponse })
      }
    },
    tWebConnectPrivacyInformationHtml() {
      if (this.tWebConnectPrivacyInformationHtml !== this.selectedWidgetTranslation.privacyInformationHtml) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ privacyInformationHtml: this.tWebConnectPrivacyInformationHtml })
      }
    }
  },

  computed: {
    ...mapGetters({
      dialog: 'dialog',
      userGroups: 'user_groups',
      selectedLanguageForWebConnect: 'widgetTranslation/selectedLanguageForWebConnect',
      selectedWidgetTranslation: 'widgetTranslation/selectedWidgetTranslation',
      hasLoadedWidgetTranslation: 'widgetTranslation/hasLoadedWidgetTranslation'
    }),
    canUpdateTextFields() {
      if (!this.selectedLanguageForWebConnect || this.selectedLanguageForWebConnect.code === 'en') return true

      return false
    },
    userGroupList() {
      try {
        return this.userGroups.map((x) => {
          return {
            name: x.name,
            id: x.id,
            users: x.users
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
        return []
      }
    },
    embedVideoURL() {
      return this.defaultVideoURL
    },
    defaultVideoURL() {
      return '/Mann-Winken-SD.mp4'
    }
  },

  data() {
    return {
      imageURL: require('@/assets/images/dialog/contact-form-background.jpeg'),
      defaultImageURL: require('@/assets/images/dialog/contact-form-background.jpeg'),
      videoURL: null,
      editorOption: {
        modules: {
          toolbar: [['bold', 'italic', 'underline', 'link']]
        }
      },
      showAddEditRoute: false,
      routeGroup: null,
      routeDisplayText: '',
      showRoutingScreenPreview: false,
      isAddingRoute: false,
      privacyInformationHtml: '',
      counterDangerPopupText: false,
      counterDangerPopupTextTranslation: false,
      automatedChatResponse: null,
      isAutomatedChatResponseEnabled: false,
      isPrivacyInformationEnabled: false,
      tWebConnectAutomatedChatResponse: '',
      tWebConnectPrivacyInformationHtml: '',
      isShowRoutingScreen: false,
      updateKey: Math.random().toString(36).substring(2, 15),
      updateKey2: Math.random().toString(36).substring(2, 15)
    }
  },

  beforeMount() {
    this.automatedChatResponse = this.$i18n.t('webConnect.automatedChatReponse')
    if (this.dialog && !this.dialog.communicationOptions) {
      const _update = {
        communicationOptions: {
          chat: true,
          audio: true,
          video: true
        }
      }
      this.updateDialogPartial({ communicationOptions: _update })
    }

    if (this.dialog && !this.dialog.isShowLeadFormBeforeConversation) {
      this.updateDialogPartial({ isShowLeadFormBeforeConversation: false })
    }
    if (this.dialog && !this.dialog.isShowRoutingScreen) {
      this.updateDialogPartial({ isShowRoutingScreen: false })
    }
    this.isShowRoutingScreen = this.dialog.isShowRoutingScreen

    if (this.dialog && !this.dialog.leadForm) {
      const _leadForm = {
        fields: {
          name: {
            enabled: true,
            required: true
          },
          email: {
            enabled: true,
            required: true
          }
        },
        isPrivacyInformationEnabled: false,
        privacyInformationHtml: ''
      }

      this.updateDialogPartial({ leadForm: _leadForm })
    } else {
      this.isPrivacyInformationEnabled = this.dialog.leadForm.isPrivacyInformationEnabled
      this.privacyInformationHtml = this.dialog.leadForm.privacyInformationHtml
    }

    if (this.dialog && typeof this.dialog.isAutomatedChatResponseEnabled !== 'undefined') {
      this.isAutomatedChatResponseEnabled = this.dialog.isAutomatedChatResponseEnabled
    }

    if (this.dialog && typeof this.dialog.automatedChatResponse === 'undefined') {
      this.updateDialogPartial({ automatedChatResponse: this.automatedChatResponse })
    }

    if (this.dialog && typeof this.dialog.automatedChatResponse !== 'undefined') {
      this.automatedChatResponse = this.dialog.automatedChatResponse
    }
  },
  methods: {
    ...mapActions({
      updateDialogPartial: 'updateDialogPartial',
      setLoadedWidgetTranslation: 'widgetTranslation/setLoadedWidgetTranslation',
      setHasWidgetTranslationUpdated: 'widgetTranslation/setHasWidgetTranslationUpdated',
      updateWidgetTranslation: 'widgetTranslation/updateWidgetTranslation'
    }),
    loadTranslation() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code !== 'en' && this.selectedWidgetTranslation) {
        this.tWebConnectAutomatedChatResponse = this.selectedWidgetTranslation.automatedChatResponse
        this.tWebConnectPrivacyInformationHtml = this.selectedWidgetTranslation.privacyInformationHtml
      }
    },
    addRoute() {
      if (this.isAddingRoute || !this.routeDisplayText || !this.routeGroup) return
      this.isAddingRoute = true

      const vRouteCommunicationOptions = this.dialog.routeCommunicationOptions ? [...this.dialog.routeCommunicationOptions] : []

      vRouteCommunicationOptions.push({
        displayText: this.routeDisplayText,
        group: this.routeGroup
      })

      this.updateDialogPartial({ routeCommunicationOptions: vRouteCommunicationOptions })

      this.updateKey = Math.random().toString(36).substring(2, 15)
      this.updateKey2 = Math.random().toString(36).substring(2, 15)

      this.showAddEditRoute = false
      this.routeDisplayText = ''
      this.routeGroup = null
      this.isAddingRoute = false
    },
    removeRouteCommunicationOption(index) {
      const updatedRouteCommunicationOptions = [...this.dialog.routeCommunicationOptions]
      updatedRouteCommunicationOptions.splice(index, 1)

      if (!updatedRouteCommunicationOptions || updatedRouteCommunicationOptions.length === 0) {
        this.updateDialogPartial({ routeCommunicationOptions: null })
      } else {
        this.updateDialogPartial({ routeCommunicationOptions: updatedRouteCommunicationOptions })
      }
    },
    updateCommunicationOptions(type) {
      const updatedCommunicationOptions = { ...this.dialog.communicationOptions }
      updatedCommunicationOptions[type] = !updatedCommunicationOptions[type]
      if (!updatedCommunicationOptions.chat && !updatedCommunicationOptions.audio && !updatedCommunicationOptions.video) {
        return
      }

      this.updateDialogPartial({ communicationOptions: updatedCommunicationOptions })
    },

    enableLeadFormBeforeConversation() {
      const updatedDialog = {
        isShowLeadFormBeforeConversation: !this.dialog.isShowLeadFormBeforeConversation
      }
      this.updateDialogPartial(updatedDialog)
    },
    togglePrivacyInformation() {
      const updatedLeadForm = { ...this.dialog.leadForm } || { isPrivacyInformationEnabled: false, privacyInformationHtml: '' }
      updatedLeadForm.isPrivacyInformationEnabled = !updatedLeadForm.isPrivacyInformationEnabled

      this.updateDialogPartial({ leadForm: updatedLeadForm })
    },

    toggleAutomatedChatResponse() {
      this.isAutomatedChatResponseEnabled = !this.isAutomatedChatResponseEnabled

      this.updateDialogPartial({ isAutomatedChatResponseEnabled: this.isAutomatedChatResponseEnabled })
    },

    /* Show Routing Screen Before Communication Options */
    enableRoutingScreenBeforeCommunicationOptions() {
      this.isShowRoutingScreen = !this.isShowRoutingScreen
    },
    addNewRoute() {
      if (this.dialog.routeCommunicationOptions && this.dialog.routeCommunicationOptions.length >= 6) {
        return
      }
      this.showAddEditRoute = true
      this.groups = []
    }
  }
}
</script>

<style lang="scss">
.configure-dialog-step-four {
  .quill-editor {
    height: 150px;
    .ql-toolbar {
      max-height: 42px;
    }
    .ql-disabled {
      background-color: #eee;
    }
    .ql-container {
      max-height: 108px;
      .ql-editor {
        font-family: 'Lato';
      }
    }
  }

  &__select-link-message {
    padding: 10px;
    color: rgb(59, 134, 247);
    text-align: center;

    &:hover {
      cursor: pointer;
    }

    &__icon {
      margin-right: 5px;
    }
  }

  .route-options-modal {
    .vs-con-input {
      .vs-input--input.normal {
        font-size: 1.1rem !important;
        font-family: 'Lato';
      }
      .vs-input--placeholder.normal {
        font-family: 'Lato';
        font-size: 14px;
        font-weight: 400;
      }
      span {
        font-family: 'Lato';
      }
    }
  }
  .modal-close {
    width: 15px;
    height: 15px;
    display: flex;
    cursor: pointer;
  }

  .request-options {
    display: flex;
    @media only screen and (max-width: 680px) {
      flex-direction: column !important;
    }
    @media only screen and (min-width: 681px) {
      flex-direction: row !important;
    }
    &--lightgray {
      color: #d3d3d3;
    }

    .delete-btn-bck {
      background: #fff !important;
      border-radius: 10px;
      color: rgba(var(--vs-secondary), 1) !important;
    }

    &--delete-btn {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px;
    }
  }

  .flex-view {
    display: flex;
    width: 100%;

    @media only screen and (max-width: 1440px) {
      flex-direction: column !important;
    }
    @media only screen and (min-width: 1441px) {
      flex-direction: row !important;
    }

    &-button {
      @media only screen and (max-width: 900px) {
        width: 100%;
      }
      &--big {
        height: 64px;
      }
      width: 154px;
      height: 38px;
      background: #53a5f2;
      border: 1px solid #53a5f2;
      border-radius: 6px;
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .text {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
      }

      &--disabled {
        background: #ffffff;
        border: 1.5px solid #b2b2b2;

        .text {
          color: #b2b2b2;
        }
      }
    }

    @media only screen and (max-width: 769px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    @media only screen and (min-width: 770px) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  .ql-editor {
    min-height: 200px;
    font-family: 'Lato';
  }
}
</style>
