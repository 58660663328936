<template>
  <div class="contact-form-container">
    <div :class="!HAS_ACCESS ? 'blur-background' : ''">
      <contact-form :infoImg="infoImg"></contact-form>
    </div>
    <contact-form-message v-if="!HAS_ACCESS"></contact-form-message>
  </div>
</template>
<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

import { mapGetters } from 'vuex'

import ContactForm from '@/components/dialog/ContactForm.vue'
import ContactFormMessage from '@/components/upgrade-messages/ContactFormMessage.vue'
export default {
  components: {
    ContactForm,
    ContactFormMessage
  },
  props: {
    infoImg: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      company: 'company'
    }),
    HAS_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('contact-form') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    }
  }
}
</script>

<style lang="scss" scoped>
.blur-background {
  filter: blur(3px);
}

.contact-form-container {
  min-height: 300px;
  position: relative;
}
</style>
