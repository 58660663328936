var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact-form-container" },
    [
      _c(
        "div",
        { class: !_vm.HAS_ACCESS ? "blur-background" : "" },
        [_c("contact-form", { attrs: { infoImg: _vm.infoImg } })],
        1
      ),
      !_vm.HAS_ACCESS ? _c("contact-form-message") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }