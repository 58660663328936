var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "configure-dialog-step-four" },
    [
      _c(
        "connect-modal",
        {
          attrs: {
            show: _vm.showAddEditRoute,
            width: "450px",
            height: "250px",
            bkcolor: "#F7F7F7",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-close",
              staticStyle: { "margin-left": "auto" },
              on: {
                click: function ($event) {
                  _vm.showAddEditRoute = false
                },
              },
            },
            [
              _c("close-circle-icon", {
                staticClass: "pa-bk-secondary",
                attrs: { "show-circle": false, width: 14, height: 14 },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "route-options-modal flex-column p-5" }, [
            _c(
              "div",
              { staticClass: "p-2 w-full" },
              [
                _c("vs-input", {
                  staticClass: "text",
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("inputs.displayName"),
                  },
                  model: {
                    value: _vm.routeDisplayText,
                    callback: function ($$v) {
                      _vm.routeDisplayText = $$v
                    },
                    expression: "routeDisplayText",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "p-2 w-full" },
              [
                _c("v-select", {
                  staticClass: "mb-4 md:mb-0 w-full",
                  attrs: {
                    placeholder: _vm.$t("vue.selectUserGroup"),
                    options: _vm.userGroupList,
                    searchable: false,
                    clearable: false,
                    label: "name",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "option",
                      fn: function (option) {
                        return [
                          _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(option.name) +
                                "\n            "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "no-options",
                      fn: function () {
                        return [_vm._v(_vm._s(""))]
                      },
                      proxy: true,
                    },
                    {
                      key: "list-footer",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "configure-dialog-step-four__select-link-message",
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push({
                                    name: "company-setup",
                                    params: { action: "create-user-group" },
                                  })
                                },
                              },
                            },
                            [
                              _c("UserIcon", {
                                staticClass:
                                  "configure-dialog-step-four__select-link-message__icon",
                                attrs: {
                                  color: "#3B86F7",
                                  width: 13,
                                  height: 13,
                                },
                              }),
                              _vm._v(
                                "\n\n              " +
                                  _vm._s(_vm.$t("vue.createNewUserGroup")) +
                                  "\n            "
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.routeGroup,
                    callback: function ($$v) {
                      _vm.routeGroup = $$v
                    },
                    expression: "routeGroup",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "p-2 w-full" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "w-full",
                    attrs: { block: "" },
                    on: { click: _vm.addRoute },
                  },
                  [_vm._v(" Add ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "flex-box-column-start" }, [
        _c(
          "div",
          { staticClass: "vx-row w-full" },
          [
            _c(
              "vs-col",
              {
                staticClass: "flex-box-row-start w-full",
                attrs: { "vs-w": "12" },
              },
              [
                _c("div", { staticClass: "headingfont mr-2" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("vue.communicationOptionsText")) +
                      "\n        "
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "request-options mt-4 flex flex-row" }, [
        _c(
          "form",
          { staticClass: "flex flex-column vx-col lg:w-6/12 w-full mt-2 mr-4" },
          [
            _c("div", { staticClass: "mt-4 flex flex-column w-full" }, [
              _c("div", { staticClass: "step-headline mb-2" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("vue.activateRoutingScreen")) +
                    "\n        "
                ),
              ]),
              _c("div", {
                staticClass: "request-options--lightgray",
                domProps: {
                  innerHTML: _vm._s(_vm.$t("vue.activateRoutingScreenInfo")),
                },
              }),
            ]),
            _c("div", { staticClass: "flex-view" }, [
              _c("div", { staticClass: "mt-4 flex items-center" }, [
                _c("div", { staticClass: "mr-2" }, [
                  _c(
                    "div",
                    {
                      on: {
                        click:
                          _vm.enableRoutingScreenBeforeCommunicationOptions,
                      },
                    },
                    [
                      _c("toggle-switch-icon", {
                        attrs: {
                          enabled: _vm.isShowRoutingScreen,
                          width: 40,
                          height: 40,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "mr-2" }, [
                  _vm._v(_vm._s(_vm.$t("vue.yes"))),
                ]),
                _vm.isShowRoutingScreen
                  ? _c(
                      "div",
                      {
                        staticClass: "flex-view-button p-3 w-full",
                        class: {
                          "flex-view-button--disabled":
                            _vm.dialog.routeCommunicationOptions &&
                            _vm.dialog.routeCommunicationOptions.length >= 6,
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.addNewRoute()
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("add-contact-icon", {
                              staticClass: "mr-2",
                              attrs: {
                                width: 20,
                                height: 20,
                                color:
                                  _vm.dialog.routeCommunicationOptions &&
                                  _vm.dialog.routeCommunicationOptions.length >=
                                    6
                                    ? "#b2b2b2"
                                    : "#ffffff",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(_vm._s(_vm.$t("vue.addRoute"))),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm.isShowRoutingScreen
              ? _c("div", { staticClass: "mt-4 flex w-full" }, [
                  _c("div", { staticClass: "mb-2" }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("vue.routingScreenButtons"))),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.isShowRoutingScreen && _vm.dialog.routeCommunicationOptions
              ? _c(
                  "div",
                  { staticClass: "flex-view" },
                  _vm._l(
                    _vm.dialog.routeCommunicationOptions,
                    function (option, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass:
                            "flex-view-button flex-view-button--big relative",
                        },
                        [
                          _c("div", { staticClass: "text" }, [
                            _vm._v(_vm._s(option.displayText)),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "request-options--delete-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.removeRouteCommunicationOption(i)
                                },
                              },
                            },
                            [
                              _c("close-circle-icon", {
                                staticClass: "delete-btn-bck",
                                attrs: {
                                  "show-circle": false,
                                  width: 14,
                                  height: 14,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }
                  ),
                  0
                )
              : _vm._e(),
            _c("div", { staticClass: "mt-12 flex w-full" }, [
              _c("div", { staticClass: "step-headline mb-2" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("vue.automatedChatResponseLabel")) +
                    "\n        "
                ),
              ]),
            ]),
            _c("div", { staticClass: "mt-4 flex items-center" }, [
              _c("div", { staticClass: "mr-2" }, [
                _c(
                  "div",
                  { on: { click: _vm.toggleAutomatedChatResponse } },
                  [
                    _c("toggle-switch-icon", {
                      attrs: {
                        enabled: _vm.isAutomatedChatResponseEnabled,
                        width: 40,
                        height: 40,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.$t("vue.yes"))),
              ]),
            ]),
            _c("div", { staticClass: "flex-col" }, [
              !_vm.canUpdateTextFields
                ? _c("div", { staticClass: "w-full bold" }, [_vm._v("English")])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "w-full" },
                [
                  _c("vs-textarea", {
                    attrs: {
                      counter: "300",
                      width: "400px",
                      height: "100px",
                      label: _vm.$t("vue.automatedChatResponse"),
                      "counter-danger": _vm.counterDangerPopupText,
                    },
                    on: {
                      "update:counterDanger": function ($event) {
                        _vm.counterDangerPopupText = $event
                      },
                      "update:counter-danger": function ($event) {
                        _vm.counterDangerPopupText = $event
                      },
                    },
                    model: {
                      value: _vm.automatedChatResponse,
                      callback: function ($$v) {
                        _vm.automatedChatResponse = $$v
                      },
                      expression: "automatedChatResponse",
                    },
                  }),
                ],
                1
              ),
              !_vm.canUpdateTextFields
                ? _c("div", { staticClass: "w-full" }, [
                    _c("div", { staticClass: "w-full bold" }, [
                      _vm._v(
                        _vm._s(this.selectedLanguageForWebConnect.lnFullText)
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "vs-col w-full" },
                      [
                        _c("vs-textarea", {
                          attrs: {
                            counter: "300",
                            height: "100px",
                            label: _vm.$t("vue.automatedChatResponse"),
                            "counter-danger":
                              _vm.counterDangerPopupTextTranslation,
                          },
                          on: {
                            "update:counterDanger": function ($event) {
                              _vm.counterDangerPopupTextTranslation = $event
                            },
                            "update:counter-danger": function ($event) {
                              _vm.counterDangerPopupTextTranslation = $event
                            },
                          },
                          model: {
                            value: _vm.tWebConnectAutomatedChatResponse,
                            callback: function ($$v) {
                              _vm.tWebConnectAutomatedChatResponse = $$v
                            },
                            expression: "tWebConnectAutomatedChatResponse",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "mt-12 flex w-full" }, [
              _c("div", { staticClass: "step-headline mb-2" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("vue.communicationOptionsLabel")) +
                    "\n        "
                ),
              ]),
            ]),
            _c("div", { staticClass: "flex-view" }, [
              _c(
                "div",
                {
                  staticClass: "flex-view-button",
                  class: {
                    "flex-view-button--disabled":
                      _vm.dialog.communicationOptions &&
                      !_vm.dialog.communicationOptions.chat,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.updateCommunicationOptions("chat")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "margin-right": "10px", height: "18px" } },
                    [
                      _c("chat-icon", {
                        attrs: {
                          width: 18,
                          height: 18,
                          color:
                            _vm.dialog.communicationOptions &&
                            !_vm.dialog.communicationOptions.chat
                              ? "#b2b2b2"
                              : "#ffffff",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.$t("vue.chat"))),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "flex-view-button",
                  class: {
                    "flex-view-button--disabled":
                      _vm.dialog.communicationOptions &&
                      !_vm.dialog.communicationOptions.audio,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.updateCommunicationOptions("audio")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "margin-right": "10px", height: "18px" } },
                    [
                      _c("vertical-phone-icon", {
                        attrs: {
                          width: 18,
                          height: 18,
                          color:
                            _vm.dialog.communicationOptions &&
                            !_vm.dialog.communicationOptions.audio
                              ? "#b2b2b2"
                              : "#ffffff",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.$t("vue.phone"))),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "flex-view-button",
                  class: {
                    "flex-view-button--disabled":
                      _vm.dialog.communicationOptions &&
                      !_vm.dialog.communicationOptions.video,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.updateCommunicationOptions("video")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "margin-right": "10px", height: "20px" } },
                    [
                      _c("video-call-icon", {
                        attrs: {
                          width: 20,
                          height: 20,
                          color:
                            _vm.dialog.communicationOptions &&
                            !_vm.dialog.communicationOptions.video
                              ? "#b2b2b2"
                              : "#ffffff",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.$t("vue.video"))),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "mt-4 flex w-full" }, [
              _c("div", { staticClass: "mb-2" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm.$t("vue.activateLeadFormBeforeConversation"))
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "flex-view" }, [
              _c("div", { staticClass: "mt-4 flex items-center" }, [
                _c("div", { staticClass: "mr-2" }, [
                  _c(
                    "div",
                    { on: { click: _vm.enableLeadFormBeforeConversation } },
                    [
                      _c("toggle-switch-icon", {
                        attrs: {
                          enabled: _vm.dialog.isShowLeadFormBeforeConversation,
                          width: 40,
                          height: 40,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "mr-2" }, [
                  _vm._v(_vm._s(_vm.$t("vue.yes"))),
                ]),
              ]),
            ]),
            _vm.dialog.isShowLeadFormBeforeConversation
              ? [
                  _c("div", { staticClass: "mt-4 flex w-full" }, [
                    _c("div", { staticClass: "mb-2" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("vue.enablePrivacyInformation"))),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "mt-4 flex items-center" }, [
                    _c("div", { staticClass: "mr-2" }, [
                      _c(
                        "div",
                        { on: { click: _vm.togglePrivacyInformation } },
                        [
                          _c("toggle-switch-icon", {
                            attrs: {
                              enabled:
                                _vm.dialog.leadForm.isPrivacyInformationEnabled,
                              width: 40,
                              height: 40,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "mr-2" }, [
                      _vm._v(_vm._s(_vm.$t("vue.yes"))),
                    ]),
                  ]),
                  _vm.dialog.leadForm.isPrivacyInformationEnabled
                    ? _c(
                        "div",
                        { staticClass: "mt-4 mr-2 w-full" },
                        [
                          !_vm.canUpdateTextFields
                            ? _c("div", { staticClass: "w-full bold" }, [
                                _vm._v("English"),
                              ])
                            : _vm._e(),
                          _c("quill-editor", {
                            ref: "quillEditorA",
                            attrs: { options: _vm.editorOption },
                            model: {
                              value: _vm.privacyInformationHtml,
                              callback: function ($$v) {
                                _vm.privacyInformationHtml = $$v
                              },
                              expression: "privacyInformationHtml",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dialog.leadForm.isPrivacyInformationEnabled &&
                  !_vm.canUpdateTextFields
                    ? _c(
                        "div",
                        { staticClass: "mr-2 w-full" },
                        [
                          !_vm.canUpdateTextFields
                            ? _c("div", { staticClass: "w-full bold" }, [
                                _vm._v(
                                  _vm._s(
                                    this.selectedLanguageForWebConnect
                                      .lnFullText
                                  )
                                ),
                              ])
                            : _vm._e(),
                          _c("quill-editor", {
                            ref: "quillEditorATranslation",
                            attrs: { options: _vm.editorOption },
                            model: {
                              value: _vm.tWebConnectPrivacyInformationHtml,
                              callback: function ($$v) {
                                _vm.tWebConnectPrivacyInformationHtml = $$v
                              },
                              expression: "tWebConnectPrivacyInformationHtml",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        ),
        _c("div", { staticClass: "vx-col lg:w-6/12 w-full mt-2 ml-4" }, [
          _c("div", { staticClass: "step-headline" }, [
            _vm._v("\n        " + _vm._s(_vm.$t("vue.preview")) + "\n      "),
          ]),
          _c(
            "div",
            { staticClass: "flex flex-preview" },
            [
              _vm.isShowRoutingScreen
                ? _c("welcome-screen-preview", {
                    key: _vm.updateKey,
                    attrs: {
                      id: "request-options",
                      uploadType: _vm.dialog.useOneMediaForAllSteps
                        ? "allMedia"
                        : "welcomeScreenMedia",
                      uploadSubtype:
                        _vm.dialog.useOneMediaForAllSteps &&
                        typeof _vm.dialog.allMedia === "object" &&
                        _vm.dialog.allMedia !== null
                          ? "welcomeScreenMedia"
                          : "",
                      dialog: _vm.dialog,
                      imageURL: _vm.defaultImageURL,
                      embedVideoURL: _vm.embedVideoURL,
                      showRoutingScreenPreview: true,
                    },
                  })
                : _vm._e(),
              _c("welcome-screen-preview", {
                key: _vm.updateKey2,
                attrs: {
                  id: "request-options",
                  uploadType: _vm.dialog.useOneMediaForAllSteps
                    ? "allMedia"
                    : "welcomeScreenMedia",
                  uploadSubtype:
                    _vm.dialog.useOneMediaForAllSteps &&
                    typeof _vm.dialog.allMedia === "object" &&
                    _vm.dialog.allMedia !== null
                      ? "welcomeScreenMedia"
                      : "",
                  dialog: _vm.dialog,
                  imageURL: _vm.defaultImageURL,
                  embedVideoURL: _vm.embedVideoURL,
                  showRoutingScreenPreview: false,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }