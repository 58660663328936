var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.tabKey,
      staticClass: "configure-dialog-step-two flex-box-column-start",
    },
    [
      _c(
        "div",
        { staticClass: "vx-row w-full mb-5" },
        [
          _c(
            "vs-col",
            {
              staticClass: "flex-box-row-start w-full",
              attrs: { "vs-w": "12" },
            },
            [
              _c(
                "vs-button",
                {
                  staticClass: "mr-2",
                  attrs: {
                    color: "primary",
                    type:
                      _vm.dialog.design === "pathadvice" ? "filled" : "border",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.changeDialogDesign("pathadvice")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("vue.standardMode")) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "ml-2",
                  attrs: {
                    color: "primary",
                    type: _vm.dialog.design === "custom" ? "filled" : "border",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.changeDialogDesign("custom")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("vue.developerMode")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.dialog.design === "custom"
        ? [
            _c(
              "div",
              { staticClass: "vx-row w-full flex-box-column-start" },
              [
                _c("vs-col", { attrs: { "vs-type": "flex", "vs-w": "12" } }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("vue.embedCustomDesignText"))),
                  ]),
                ]),
                _c("vs-col", { attrs: { "vs-w": "12" } }, [
                  _c(
                    "div",
                    { staticClass: "flex-box-row-start" },
                    [
                      _c(
                        "prism",
                        {
                          staticClass: "rounded-lg mr-2",
                          attrs: { language: "javascript" },
                        },
                        [_vm._v(_vm._s(_vm.customContent))]
                      ),
                      _c(
                        "vx-tooltip",
                        {
                          attrs: {
                            text: _vm.$t("info.customDesign"),
                            position: "top",
                          },
                        },
                        [_c("img", { attrs: { src: _vm.infoImg } })]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "vs-col",
                  { attrs: { "vs-w": "12" } },
                  [
                    _c(
                      "vs-button",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: _vm.customContent,
                            expression: "customContent",
                            arg: "copy",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.onCopy,
                            expression: "onCopy",
                            arg: "success",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:error",
                            value: _vm.onError,
                            expression: "onError",
                            arg: "error",
                          },
                        ],
                        attrs: {
                          type: "border",
                          size: "small",
                          "icon-pack": "feather",
                          icon: "icon-clipboard",
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("vue.copy")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.dialog.design === "pathadvice"
        ? [
            _c(
              "div",
              { staticClass: "vx-row w-full" },
              [
                _c(
                  "vs-col",
                  {
                    staticClass: "flex-box-row-start w-full",
                    attrs: { "vs-w": "12" },
                  },
                  [
                    _c("div", { staticClass: "headingfont" }, [
                      _vm._v(_vm._s(_vm.$t("vue.dialogDisplayType"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "ml-2" },
                      [
                        _c(
                          "vx-tooltip",
                          {
                            attrs: {
                              text: _vm.$t("info.dialogDisplayType"),
                              position: "top",
                            },
                          },
                          [_c("img", { attrs: { src: _vm.infoImg } })]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "flex-row-start vx-row w-full mt-5" }, [
              _c(
                "div",
                {
                  staticClass: "vx-col w-full mt-2",
                  class: "lg:w-" + _vm.leftScreenVSWidth + "/12",
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex-box-column-start align-items-start" },
                    [
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "mb-3 headingfont inline-block pt-0" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("vue.preview")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _c("preview-dialog", {
                        attrs: {
                          dialog: _vm.dialog,
                          buttonContainerMinWidth: _vm.buttonContainerMinWidth,
                          embedVideoURL: _vm.embedVideoURL,
                          imageURL: _vm.imageURL,
                          smallButtonImage: _vm.smallButtonImage,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "vx-col w-full mt-5",
                  class: "lg:w-" + _vm.rightScreenVSWidth + "/12",
                },
                [
                  _c("display-options", {
                    attrs: {
                      id: "s2",
                      infoImg: _vm.infoImg,
                      dialogId: _vm.dialogId,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }