<template>
  <text-and-dialog-type :infoImg="infoImg" :dialogId="dialogId" />
</template>
<script>
import TextAndDialogType from '../../../components/dialog/TextAndDialogType.vue'
export default {
  components: {
    TextAndDialogType
  },
  props: {
    infoImg: {
      type: String,
      required: true
    },
    dialogId: {
      type: String,
      required: true
    }
  }
}
</script>
