<template>
  <div>
    <connect-modal :title="$t('vue.dataProtectionHeadline')" :width="'600px'" :show="showDataProtectionPopup">
      <div style="margin-left: auto; width: 15px; height: 15px; cursor: pointer" @click="showDataProtectionPopup = false">
        <close-circle-icon class="pa-bk-secondary" :show-circle="false" :width="14" :height="14"></close-circle-icon>
      </div>
      <legal-notice class="wi-legacy-notice" />
    </connect-modal>

    <div class="step-one flex-box-column-start mt-5">
      <!-- Step one Start -->
      <div v-if="IS_SHOPIFY_ACCOUNT" class="vx-row w-full">
        <vs-col vs-w="1" class="mr-12">
          <img width="39px" height="39px" :src="require('@/assets/images/elements/first.svg')" />
        </vs-col>
        <vs-col vs-type="flex" vs-w="9">
          <div class="vx-row w-full flex-box-column-start integration-steps items-start">
            <ol>
              <li>
                <div class="flex flex-column">
                  <div>
                    <label>{{ $t('shopify.copyWidgetId') }}</label>
                  </div>
                  <div>
                    <div class="flex-box-row-start">
                      <prism class="rounded-lg mr-2" language="javascript">{{ dialogId }}</prism>
                      <vx-tooltip :text="$t('shopify.codeInfo')" position="top">
                        <img :src="infoImg" />
                      </vx-tooltip>
                    </div>
                  </div>
                  <div>
                    <vs-button
                      type="border"
                      size="small"
                      icon-pack="feather"
                      icon="icon-clipboard"
                      v-clipboard:copy="dialogId"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                    >
                      {{ $t('vue.copy') }}
                    </vs-button>
                  </div>
                </div>
              </li>
              <li>
                <div class="flex flex-column">
                  <div>
                    <label>{{ $t('shopify.pasteWidgetInShopifyStore') }}</label>
                  </div>
                  <div>
                    <vs-button type="border" class="mt-2 mr-2 mb-2" @click="goToShopifyStore">
                      {{ $t('shopify.goToShopifyStore') }}
                    </vs-button>
                  </div>
                  <div>
                    <img width="800px" height="328px" :src="require('@/assets/images/shopify/shopify-store.png')" />
                  </div>
                </div>
              </li>
              <li>
                <div class="flex flex-column">
                  <div>
                    <label>{{ $t('shopify.clickOnSave') }}</label>
                  </div>
                </div>
              </li>
            </ol>
          </div>
        </vs-col>
      </div>

      <div v-else class="vx-row w-full">
        <vs-col vs-w="1" class="mr-12">
          <img width="39px" height="39px" :src="require('@/assets/images/elements/first.svg')" />
        </vs-col>
        <vs-col vs-type="flex" vs-w="9">
          <div class="vx-row w-full flex-box-column-start">
            <vs-col vs-type="flex" vs-w="12">
              <label>{{ $t('vue.embedCodeText') }}</label>
            </vs-col>
            <vs-col vs-w="12">
              <div class="flex-box-row-start">
                <prism class="rounded-lg mr-2" language="javascript">{{ content }}</prism>
                <vx-tooltip :text="$t('info.code')" position="top">
                  <img :src="infoImg" />
                </vx-tooltip>
              </div>
            </vs-col>
            <vs-col vs-w="12">
              <vs-button
                type="border"
                size="small"
                icon-pack="feather"
                icon="icon-clipboard"
                v-clipboard:copy="content"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                {{ $t('vue.copy') }}
              </vs-button>
            </vs-col>
          </div>
        </vs-col>
      </div>
      <!-- Step one End -->

      <div class="vx-row w-full mt-5">
        <vs-col vs-w="1" class="mr-12">
          <img width="39px" height="39px" :src="require('@/assets/images/elements/second.svg')" />
        </vs-col>
        <vs-col vs-type="flex" vs-w="9">
          <div class="vx-row w-full flex-box-column-start">
            <vs-col vs-type="flex" vs-w="12">
              <label>{{ $t('vue.testIntegrationText') }}</label>
            </vs-col>
            <vs-col vs-w="12">
              <div class="flex-box-row-start">
                <vs-button type="border" class="mt-2 mr-2 mb-2" @click="testIntegration">
                  {{ $t('vue.testIntegration') }}
                </vs-button>
                <vx-tooltip :text="$t('info.testingIntegration')" position="top">
                  <img :src="infoImg" />
                </vx-tooltip>
              </div>
            </vs-col>
            <vs-col vs-w="12">
              <div class="flex-box-row-start">
                <img
                  :src="require('@/assets/images/elements/ok.png')"
                  class="mr-2"
                  v-if="integrationSuccess == 1 || dialog.codeIntegrated"
                  width="25"
                  height="25"
                />
                <img :src="require('@/assets/images/elements/no.png')" class="mr-2" v-if="integrationSuccess == 0" width="25" height="25" />
                <div v-if="integrationSuccess == 1 || dialog.codeIntegrated" style="color: green">{{ $t('vue.successfulIntegration') }}</div>
                <div v-if="integrationSuccess == 0" style="color: red">{{ $t('vue.failedIntegration') }}</div>
              </div>
            </vs-col>
          </div>
        </vs-col>
      </div>

      <div class="vx-row w-full mt-5">
        <vs-col vs-w="1" class="mr-12">
          <img width="39px" height="39px" :src="require('@/assets/images/elements/third.svg')" />
        </vs-col>
        <vs-col vs-type="flex" vs-w="9">
          <div class="vx-row w-full flex-box-column-start">
            <vs-col vs-type="flex" vs-w="12">
              <label>{{ $t('vue.privacyPolicyText') }}</label>
            </vs-col>
            <vs-col vs-w="12">
              <div class="flex-box-row-start">
                <vs-button type="border" icon-pack="feather" class="mt-2 mr-2 mb-2" icon="icon-lock" @click="showDataProtectionPopup = true">
                  {{ $t('vue.dataProtectionTextButton') }}
                </vs-button>
                <vx-tooltip :text="$t('info.privacyPolicy')" position="top">
                  <img :src="infoImg" />
                </vx-tooltip>
              </div>
            </vs-col>
          </div>
        </vs-col>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Prism from 'vue-prism-component'
import LegalNotice from '../LegalNotice.vue'

import ConnectModal from '@/components/ConnectModal.vue'
import CloseCircleIcon from '@/components/icons/CloseCircleIcon.vue'

export default {
  components: {
    Prism,
    LegalNotice,
    ConnectModal,
    CloseCircleIcon
  },
  props: {
    infoImg: {
      type: String,
      required: true
    },
    dialogId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      dialog: 'dialog',
      company: 'company'
    }),
    IS_SHOPIFY_ACCOUNT() {
      return this.company && this.company.isShopifyAccount && this.company.shopifyStoreId
    }
  },
  data() {
    return {
      content: '',
      testUrl: '',
      integrationSuccess: null,
      showDataProtectionPopup: false,
      oldDialogName: this.diaog && this.dialog.name ? this.dialog.name : '',
      unsubscribeShop: null,
      shop: null
    }
  },
  beforeMount() {
    if (this.company && this.company.isShopifyAccount && this.company.shopifyStoreId) {
      try {
        const query = this.$db.collection('shopify-accounts').doc(this.company.shopifyStoreId.toString())
        this.unsubscribeShop = query.onSnapshot(async (ref) => {
          this.shop = ref.data()
        })
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error.message)
      }
    }
  },
  mounted() {
    /* eslint-disable */
    this.content = `<script type="text/javascript" async>
(function(d,s){
var l=d.createElement(s),e=d.getElementsByTagName(s)[0];
l.async=true;l.type='text/javascript';
l.src='${window.location.protocol}//${window.location.host}/embed.js';
l.id='${this.dialogId}';
e.parentNode.insertBefore(l,e);})(document,'script');<\/script>`
  },
  beforeDestroy() {
    if (this.unsubscribeShop) {
      this.unsubscribeShop()
    }
  },

  methods: {
    goToShopifyStore() {
      const url = `https://${this.shop.shop.domain}/admin/themes/current/editor?context=apps&activateAppId=${this.$shopifyThemeAppExtensionId}/app-embed`
      window.open(url, '_blank')
    },
    async testIntegration() {
      if (this.dialog.codeIntegrated) {
        this.integrationSuccess = true
        return
      }

      this.$vs.loading()
      const config = {
        dialogId: this.dialogId
      }
      const isDialogIntegrated = this.$functions.httpsCallable('isDialogIntegrated')
      const rs = await isDialogIntegrated(config)
      this.integrationSuccess = rs && !!rs.data ? 1 : 0
      if (this.integrationSuccess) {
        this.$db.collection('dialogs').doc(this.dialogId).set({ codeIntegrated: true }, { merge: true })
      }
      this.$vs.loading.close()
    },
    onCopy() {
      this.$vs.notify({
        title: 'Success',
        text: 'Text copied successfully',
        color: 'success',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-check-circle'
      })
    },
    onError() {
      this.$vs.notify({
        title: 'Failed',
        text: 'Error in copying text',
        color: 'danger',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-alert-circle'
      })
    }
  }
}
</script>
<style lang="scss">
.wi-legacy-notice {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.integration-steps {
  ol {
    list-style-type: lower-alpha;
    margin: 0;
    padding: 0;
    display: list-item;
    list-style-position: inside;
    padding-left: 0;
  }
  ol::marker {
    display: none;
    visibility: hidden;
    content: '';
  }
  li {
    margin-top: 10px;
  }
  li::marker {
    font-weight: bold;
  }
}
</style>
