<template>
  <div>
    <div v-html="dataProtectionTextHtml"></div>
    <div class="dialog-consent">
      <div
        class="dialog-consent-content flex flex-col items-center justify-center dialog-consent-absolute"
        :class="showConsentToolSelection ? '' : 'left-p1000'"
      >
        <div class="dialog-consent-bold">{{ $t('vue.consentToolQuestion') }}</div>
        <div class="flex flex-row justify-center mt-5">
          <div class="dialog-consent-button flex justify-center items-center m-2" @click="useContentTool(true)">{{ $t('vue.usingConsentTool') }}</div>
          <div class="dialog-consent-button flex justify-center items-center m-2" @click="useContentTool(false)">{{ $t('vue.notUsingConsentTool') }}</div>
        </div>
      </div>
      <div class="dialog-consent-content p-5 dialog-consent-absolute" :class="showConsentToolSelection || !usingConsentTool ? 'left-500' : ''">
        <div>
          <div class="flex flex-row items-center mb-2 cursor-pointer" @click="onBackToSelection()">
            <div class="flex items-center justify-center">
              <div class="mr-2"><back-icon></back-icon></div>
              <div style="height: 25px">{{ $t('vue.back').toUpperCase() }}</div>
            </div>
          </div>
          <div class="flex flex-row">
            <div
              class="dialog-consent-language flex justify-center items-center"
              :class="selectedLanguage === 'de' ? 'dialog-consent-language-active' : 'dialog-consent-language-inactive'"
              @click="selectedLanguage = 'de'"
            >
              {{ $t('vue.german') }}
            </div>
            <div
              class="dialog-consent-language flex justify-center items-center"
              :class="selectedLanguage === 'en' ? 'dialog-consent-language-active' : 'dialog-consent-language-inactive'"
              @click="selectedLanguage = 'en'"
            >
              {{ $t('vue.english') }}
            </div>
          </div>
          <scroll-view ref="legalNoticeText" class="dialog-content-scroll-area">
            <div v-if="selectedLanguage === 'de'" :key="key" id="dv-consent-de">
              <div class="mb-5"><strong style="font-size: 1.2em">1) Textbaustein Consent Tool </strong></div>
              <p>Let's Connect ist ein Live-Chat Tool, um einem Webseitenbesucher die Kontaktaufnahme mit einem Berater zu ermöglichen.</p>
              <p class="mt-2">
                <strong>Verarbeitendes Unternehmen</strong> <br />
                PATHADVICE International GmbH<br />
                Serlesweg 3<br />
                6161 Natters<br />
                Österreich
              </p>

              <p class="mt-2">
                <strong>Datenschutzbeauftragter der verarbeitenden Firma</strong> <br />
                Michael Suitner; <a href="https://www.letsconnect.at/data-privacy" target="_blank">https://www.letsconnect.at/data-privacy</a>
              </p>

              <p class="mt-2">
                <strong>Datenverarbeitungszwecke</strong> <br />
                Der Dienst des Anbieters wird zum Zweck der Online-Kundenkommunikation eingesetzt.
              </p>
              <p class="mt-2">
                <strong>Genutzte Technologien</strong> <br />
                Diese Liste enthält alle Technologien, mit denen dieser Dienst Daten sammelt. Typische Technologien sind Cookies und Pixel, die im Browser
                platziert werden: Local Storage
              </p>
              <p class="mt-2">
                <strong>Erhobene Daten</strong><br />
                Diese Liste enthält alle Daten, die bei der oder durch die Nutzung des Service gesammelt werden: <br /><br />

                Verwendetes Gerät<br />
                Browser Sprache<br />
                Browser Typ/- Version<br />
                Seiten URL<br />
                Geografischer Standort<br />
                Datum und Uhrzeit des Zugriffs <br />
                Referrer URL<br />
                Dauer des Zugriffs<br />
                Vom Webseiten-Besucher eingegebene Kontaktdaten
              </p>
              <p class="mt-2">
                <strong>Rechtsgrundlage</strong> <br />
                Im Folgenden wird die erforderliche Rechtsgrundlage für die Verarbeitung von Daten genannt:<br />
                Art. 6 Abs. 1 a) und f) DSGVO
              </p>
              <p class="mt-2">
                <strong>Ort der Verarbeitung</strong><br />
                Europäische Union
              </p>
              <p class="mt-2">
                <strong>Aufbewahrungsdauer</strong> <br />
                Die Aufbewahrungsfrist ist die Zeitspanne, in der die gesammelten Daten für die Verarbeitung gespeichert werden. Nach der Beendigung der
                Kundenkommunikation werden die Daten gelöscht, sobald sie für die angegebenen Verarbeitungszwecke nicht mehr benötigt werden. Die maximale
                Speicherdauer beträgt 3 Jahre.
              </p>
              <p class="mt-2 mb-2">
                <strong>Datenempfänger</strong> <br />
                Im Folgenden werden die Empfänger der erhobenen Daten aufgelistet: <br />
                PATHADVICE International GmbH, Serlesweg 3, 6161 Natters, Austria
                <br /><br />

                Klicken Sie hier, um die Datenschutzbestimmungen des Datenverarbeiters zu lesen: <br />
                <a href="https://www.letsconnect.at/data-privacy" target="_blank">https://www.letsconnect.at/data-privacy</a>
              </p>

              <div class="mb-5"><strong style="font-size: 1.2em">2) Textbaustein Datenschutzerklärung (Consent Tool)</strong></div>

              <p class="mt-2">
                [Website-Betreiber] setzt auf dieser Internetseite den Let's Connect Live-Chat-Dienst der PATHADVICE International GmbH, Serlesweg 3, 6161
                Natters, Österreich, (im Nachfolgenden "Let's Connect") ein, welche eine einfache, direkte Online-Kommunikation zwischen [Website-Betreiber] und
                seinen Website Besuchern ermöglicht. Beim Betrieb von Let's Connect werden folgende Daten verarbeitet: Verwendetes Gerät, Browser Sprache,
                Browser Typ/- Version, Seiten URL, geografischer Standort, Datum und Uhrzeit des Zugriffs, Referrer URL, Dauer des Zugriffs, vom Website
                Besucher eingegebene Kontaktdaten. Die vorgenannten Daten werden ausschließlich für den Betrieb des Live-Chat-Dienstes sowie der Kommunikation
                mit Website-Besuchern benutzt. Nähere Informationen zum Dienst findet der User unter
                <a href="https://www.letsconnect.at/data-privacy" target="_blank">https://www.letsconnect.at/data-privacy</a>.
              </p>
              <p class="mt-2">
                [Website-Betreiber] benutzt den Dienst von Let's Connect auf Basis der Einwilligung nach Art. 6 Abs. 1 a) DSGVO. Darüber hinaus erfolgt der
                Einsatz von Let's Connect auch auf Basis des berechtigten Interesses nach Art. 6 Abs. 1 f) DSGVO. Mittels Let's Connect ist es
                [Website-Betreiber] möglich, eine effiziente und sichere Online-Kommunikation für den interessierten Website Besucher bereitzustellen. Nach der
                Beendigung der Kundenkommunikation werden die Daten gelöscht, sobald sie für die angegebenen Verarbeitungszwecke nicht mehr benötigt werden. Die
                maximale Speicherdauer beträgt 3 Jahre.
              </p>
            </div>

            <div v-if="selectedLanguage === 'en'" :key="key" id="dv-consent-en">
              <div class="mb-5"><strong style="font-size: 1.2em">1) Template Consent Tool </strong></div>
              <p>
                Let's Connect is a live-chat service which enables a simple, direct online communication between [website operator] and its website visitors.
              </p>
              <p class="mt-2">
                <strong>Processing Company </strong> <br />
                PATHADVICE International GmbH<br />
                Serlesweg 3<br />
                6161 Natters<br />
                Austria
              </p>

              <p class="mt-2">
                <strong>Data security official of processing company </strong> <br />
                Michael Suitner: <a href="https://www.letsconnect.at/data-privacy" target="_blank">https://www.letsconnect.at/data-privacy</a>
              </p>

              <p class="mt-2">
                <strong>Reason of Processing</strong> <br />
                The service is used for a direct online communication with website visitors.
              </p>
              <p class="mt-2">
                <strong>Used Technologies </strong> <br />
                Local Storage
              </p>
              <p class="mt-2">
                <strong>Used Data </strong><br />
                Used device, browser language, browser type/version, page URL, geographical location, date and time of access, referrer URL, duration of access,
                contact details entered by the website visitor
              </p>
              <p class="mt-2">
                <strong>Legal Basis</strong> <br />
                Art. 6 Abs. 1 a) und f) DSGVO
              </p>
              <p class="mt-2">
                <strong>Place of Processing </strong><br />
                European Union
              </p>
              <p class="mt-2">
                <strong>Storage Time </strong> <br />
                After the termination of the customer communication, the data is deleted as soon as it is no longer needed for the specified processing
                purposes. The maximum storage period is 3 years.
              </p>
              <p class="mt-2 mb-2">
                <strong>Data Receiver </strong> <br />
                PATHADVICE International GmbH, Serlesweg 3, 6161 Natters, Austria

                <a href="https://www.letsconnect.at/data-privacy" target="_blank">https://www.letsconnect.at/data-privacy</a>
              </p>
              <br /><br />
              <div class="mb-5">
                <p><strong style="font-size: 1.2em">2) Template Privacy Policy (Consent Tool)</strong></p>
              </div>

              <p class="mt-2">
                [Website operator] uses the live chat service Let's Connect of PATHADVICE International GmbH, Serlesweg 3, 6161 Natters, Austria, (hereinafter
                referred to as "Let's Connect") on this website, which enables simple, direct online communication between [website operator] and its website
                visitors. When operating Let's Connect, the following data is processed: Used device, browser language, browser type/version, page URL,
                geographical location, date and time of access, referrer URL, duration of access, contact details entered by the website visitor; The afore
                mentioned data is used exclusively for the operation of the live chat service and communication with website visitors. For more information see
                <a href="https://www.letsconnect.at/data-privacy" target="_blank">https://www.letsconnect.at/data-privacy</a>.
              </p>
              <p class="mt-2">
                [Website operator] uses the service of Let's Connect based on consent according to Art. 6 para. 1 a) DSGVO. In addition, the use of Let's
                Connect is also based on legitimate interest according to Art. 6 para. 1 f) DSGVO. By means of Let's Connect, it is possible for [website
                operator] to provide efficient and secure online communication for interested website visitors. After the termination of the customer
                communication, the data is deleted as soon as it is no longer needed for the specified processing purposes. The maximum storage period is 3
                years.
              </p>
            </div>
          </scroll-view>
        </div>
      </div>

      <div class="dialog-consent-content p-5 dialog-consent-absolute" :class="showConsentToolSelection || usingConsentTool ? 'left-500' : ''">
        <div>
          <div class="flex flex-row items-center mb-2 cursor-pointer" @click="onBackToSelection()">
            <div class="flex items-center justify-center">
              <div class="mr-2"><back-icon></back-icon></div>
              <div style="height: 25px">{{ $t('vue.back').toUpperCase() }}</div>
            </div>
          </div>
          <div class="flex flex-row">
            <div
              class="dialog-consent-language flex justify-center items-center"
              :class="selectedLanguage === 'de' ? 'dialog-consent-language-active' : 'dialog-consent-language-inactive'"
              @click="selectedLanguage = 'de'"
            >
              {{ $t('vue.german') }}
            </div>
            <div
              class="dialog-consent-language flex justify-center items-center"
              :class="selectedLanguage === 'en' ? 'dialog-consent-language-active' : 'dialog-consent-language-inactive'"
              @click="selectedLanguage = 'en'"
            >
              {{ $t('vue.english') }}
            </div>
          </div>
          <scroll-view class="dialog-content-scroll-area">
            <div v-if="selectedLanguage === 'de'" :key="key" id="dv-no-consent-de">
              <div class="mb-5"><strong style="font-size: 1.2em">Datenschutzerklärung Website Textbaustein Let's Connect </strong></div>

              <p class="mt-2">
                [Website-Betreiber] setzt auf dieser Internetseite den Let's Connect Live-Chat-Dienst der PATHADVICE International GmbH, Serlesweg 3, 6161
                Natters, Österreich, (im Nachfolgenden "Let's Connect") ein, welche eine einfache, direkte Online-Kommunikation zwischen [Website-Betreiber] und
                seinen Website Besuchern ermöglicht. Beim Betrieb von Let's Connect werden folgende Daten verarbeitet: Verwendetes Gerät, Browser Sprache,
                Browser Typ/- Version, Seiten URL, geografischer Standort, Datum und Uhrzeit des Zugriffs, Referrer URL, Dauer des Zugriffs, vom Website
                Besucher eingegebene Kontaktdaten. Die vorgenannten Daten werden ausschließlich für den Betrieb des Live-Chat-Dienstes sowie der Kommunikation
                mit Website-Besuchern benutzt. Nähere Informationen zum Dienst findet der User unter
                <a href="https://www.letsconnect.at/data-privacy" target="_blank">https://www.letsconnect.at/data-privacy</a>
              </p>
              <p class="mt-2">
                [Website-Betreiber] benutzt den Dienst von Let's Connect auf Basis des berechtigten Interesses nach Art. 6 Abs. 1 f) DSGVO. Mittels Let's
                Connect ist es [Website-Betreiber] möglich, eine effiziente und sichere Online-Kommunikation für den interessierten Website Besucher
                bereitzustellen. Nach der Beendigung der Kundenkommunikation werden die Daten gelöscht, sobald sie für die angegebenen Verarbeitungszwecke nicht
                mehr benötigt werden. Die maximale Speicherdauer beträgt 3 Jahre.
              </p>
            </div>

            <div v-if="selectedLanguage === 'en'" :key="key" id="dv-no-consent-en">
              <div class="mb-5"><strong style="font-size: 1.2em">Template for Privacy Policy Let's Connect</strong></div>

              <p class="mt-2">
                Website operator] uses the live chat service Let's Connect of PATHADVICE International GmbH, Serlesweg 3, 6161 Natters, Austria, (hereinafter
                referred to as "Let's Connect") on this website, which enables simple, direct online communication between [website operator] and its website
                visitors. When operating Let's Connect, the following data is processed: Used device, browser language, browser type/version, page URL,
                geographical location, date and time of access, referrer URL, duration of access, contact details entered by the website visitor; The afore
                mentioned data is used exclusively for the operation of the live chat service and communication with website visitors. For more information see
                <a href="https://www.letsconnect.at/data-privacy" target="_blank">https://www.letsconnect.at/data-privacy</a>.
              </p>
              <p class="mt-2">
                [Website operator] uses the service of Let's Connect based on legitimate interest according to Art. 6 para. 1 f) GDPR. By means of Let's
                Connect, it is possible for [website operator] to provide efficient and secure online communication for interested website visitors. After the
                termination of the customer communication, the data is deleted as soon as it is no longer needed for the specified processing purposes. The
                maximum storage period is 3 years.
              </p>
            </div>
          </scroll-view>
        </div>
      </div>
    </div>

    <vs-button
      v-if="!showConsentToolSelection"
      class="float-right mt-5"
      type="border"
      size="small"
      icon-pack="feather"
      icon="icon-clipboard"
      :key="key"
      @click="copyTextToClipboard"
    >
      {{ $t('vue.copy') }}
    </vs-button>
  </div>
</template>
<script>
import BackIcon from '@/components/icons/BackIcon'
import ScrollView from '@blackbp/vue-smooth-scrollbar'
export default {
  components: {
    BackIcon,
    ScrollView
  },
  data() {
    return {
      showConsentToolSelection: true,
      usingConsentTool: false,
      selectedLanguage: null,
      key: Math.random().toString(36).substring(2, 15)
    }
  },
  watch: {
    usingConsentTool() {
      this.key = Math.random().toString(36).substring(2, 15)
    },
    selectedLanguage() {
      this.key = Math.random().toString(36).substring(2, 15)
    },
    '$i18n.locale'() {
      if (this.$i18n && this.$i18n.locale) {
        const locale = this.$i18n.locale
        this.selectedLanguage = locale === 'en' || locale === 'de' ? locale : 'de'
      }
    }
  },
  computed: {
    dataProtectionTextHtml() {
      return this.$i18n.t('vue.dataProtectionNote')
    },

    copyText() {
      let divId = ''
      if (this.usingConsentTool && this.selectedLanguage === 'en') {
        divId = 'dv-consent-en'
      }
      if (this.usingConsentTool && this.selectedLanguage === 'de') {
        divId = 'dv-consent-de'
      }

      if (!this.usingConsentTool && this.selectedLanguage === 'en') {
        divId = 'dv-no-consent-en'
      }

      if (!this.usingConsentTool && this.selectedLanguage === 'de') {
        divId = 'dv-no-consent-de'
      }

      const dv = document.getElementById(divId)

      if (!dv) {
        return ''
      }

      return dv.textContent || dv.innerText || ''
    }
  },
  mounted() {
    const locale = this.$i18n.locale
    this.selectedLanguage = locale === 'en' || locale === 'de' ? locale : 'de'
  },
  methods: {
    copyTextToClipboard() {
      navigator.clipboard.writeText(this.copyText)

      /* Copy the text inside the text field */
      document.execCommand('copy')

      this.$vs.notify({
        title: 'Success',
        text: 'Text copied successfully',
        color: 'success',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-check-circle'
      })
    },
    useContentTool(val) {
      this.usingConsentTool = val
      this.showConsentToolSelection = false
    },
    onBackToSelection() {
      this.showConsentToolSelection = true
    }
  }
}
</script>
<style lang="scss">
.dialog-content-scroll-area {
  margin-top: 20px;
  height: 188px;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
.left-500 {
  left: -1000px !important;
}

.left-p1000 {
  left: 1000px !important;
}

.dialog-consent {
  position: relative;
  width: 569px;
  height: 311px;
  overflow: hidden;

  &-language {
    width: 254px;
    height: 35px;
    cursor: pointer;
    text-transform: uppercase;
    &-active {
      background: linear-gradient(0deg, #e6ecf1, #e6ecf1), #e6ecf1;
    }
    &-inactive {
      border: 1px solid #e6ecf1;
      box-sizing: border-box;
    }
    border-radius: 6px;
  }

  &-content {
    left: 0px;
    width: 569px;
    height: 311px;
    background: rgba(196, 196, 196, 0.15);
    border-radius: 6px;
    transition: left 0.35s ease-out;
  }

  &-absolute {
    position: absolute;
  }

  font-family: 'Lato';

  color: #000000;

  &-bold {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    font-style: normal;
  }

  &-button {
    width: 249px;
    height: 43px;
    background: linear-gradient(0deg, #e6ecf1, #e6ecf1), #e6ecf1;
    border-radius: 6px;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    padding: 5px;
    text-align: center;
  }
}
</style>
